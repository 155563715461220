import { useEffect, useRef, useState } from "react";
import { NavLink, useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { debounce } from "../utils/debounce";
import { getSearchPage } from "../Axios/getPage";
import TitleBlock from "../components/TitleBlock";
import { contentPages } from "../utils/pageObj";

const SearchPage = () => {

  useEffect(() => {
        window.scrollTo(0,0);
        searchInput.current.focus();
  }, [])

  const [,,theme] = useOutletContext();
  const [searchList, SetSearchList] = useState([]);
  const searchInput = useRef(null);
 
  function handleSearch(e) {
    if (e.target.value !== '') { 
      getSearchPage(e.target.value).then((response)=>{
        const data = response['hydra:member'];
        const filterActive = data.filter(element => {
          return element.active === 'on'
        })

        const lowerValue = e.target.value.toLowerCase();
        const searchStatic = contentPages.filter(page => {
          return  page.name.toLowerCase().includes(lowerValue) || page.content.toLowerCase().includes(lowerValue);
        })

        SetSearchList([...filterActive, ...searchStatic]);
      })
    }
    else { SetSearchList(''); }
  }

  return (
    <section className="search-page">
      {theme === 'light' && <div className="mont-block"></div> }х
      <TitleBlock subtitle={'Пошук по сайту'}/>
      <Container>
        <Row>
            <Col md={12} className="search-page__input">
             <input type="text" placeholder='Введіть слово для пошуку' onChange={debounce(handleSearch, 200)} ref={searchInput}/>
             {searchList?.length > 0 &&
                <div className="search-result">
                    {searchList.map((search, index)=>{
                    return <NavLink to={search.link || '/page/'+ search.synonym} className="search-result__item" key={index}>
                          <p>{search.name}<span> {search.type?.length && `(${search.type?.toLowerCase()})`}</span></p>
                        </NavLink>
                    })}
             </div>
            }
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SearchPage