import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import SliderNews from '../../components/News/SliderNews';
import TitleBlock from '../../components/TitleBlock';
import Services from "../../components/Services/Services";

const ServicesCom = () => {

    const [,,theme] = useOutletContext();

    const serviceList = [
        {id: 1, text: 'Укладання договорів про постачання електроенергії'},
        {id: 2, text: 'Укладання договорів про постачання газу'},
        // {id: 3, text: 'Внесення змін до договорів про постачання газу'},
        {id: 4, text: "Роз'яснення щодо помилково оплачених коштів"},
        {id: 5, text: 'Узгодження договірних обсягів споживання'},
        {id: 6, text: 'Видача рахунків'},
      ]
    const questionList = [
        {id: 1, text: 'Отримання підписаного ОСР-ом паспорту точки розподілу'},
        {id: 2, text: 'Отримання паперового примірника договору про надання послуги з розподілу'},
        {id: 3, text: 'Інформування щодо діючих тарифів'},
        {id: 4, text: "Роз'яснення стосовно діючих онлайн сервісів"},
      ]
    
    const requestList = [{id:1, text:'Пломбування та розпломбування електролічильника при ремонтних роботах' }];

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
  
        return (
           <>
             {theme === 'light' && <div className="mont-block"></div> }
             <TitleBlock title={'Послуги ТОВ «Львівенергозбут»'} type={'Для компаній'}/>
             <Services services = {serviceList} questions = {questionList} requests = {requestList}/>
             <SliderNews/>
          </>
        );
};


export default ServicesCom