import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import TitleBlock from '../TitleBlock';
import ConditionItem from '../Condition/ConditionItem';

const PaymentDescr = () => {
  return (
      <section className='payment-description'>
      <TitleBlock subtitle={'Роз’яснення до рахунку для непобутових клієнтів '} type={'Де шукати реквізити та суму до сплати у вашому платіжному документі'}/>
        <Container className='payment-method'>
            <Row>
            <Col md={12} className='about-video__frame'>
                <iframe
                    width="100%"
                    height="560"
                    src={`https://www.youtube.com/embed/Uljrl9-z_Fk`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                <ConditionItem 
                     url={process.env.REACT_APP_SERVER_URL + '/media/664c5c7a73b82_Zbut_opys_rakhunku_(1).pdf'} name={"Переглянути"}
                     text = {'Приклад – роз’яснення рахунку за електричну енергію, який отримують юридичні особи та фізичні особи-підприємці.'}
                     />
                </Col>
                <Col md={12}>
                    <p>Якщо ви неправильно здійснили оплату, зверніться до ТОВ “Львівенергозбут” у зручний для себе спосіб: Кол-центр, Інформаційно-консультаційний центр, Центр 
                        обслуговування клієнтів, на пошту kanc@lvivenergozbut.com.</p>
                </Col>
                <Col md={3}>
                <ConditionItem 
                     url={'/company/center-obslug'} name={"Переглянути"}
                     text = {'Центри обслуговування клієнтів'}
                     />
                </Col>
                <Col md={3}>
                <ConditionItem 
                     url={'/page/callcenter'} name={"Переглянути"}
                     text = {'Кол-центр'}
                     />
                </Col>
                <Col md={3}>
                <ConditionItem 
                     url={'/page/ikc'} name={"Переглянути"}
                     text = {'Інформаційно-консультаційний центр'}
                     />
                </Col>
            </Row>
    </Container>
    </section>
  )
}

export default PaymentDescr