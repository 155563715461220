import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'
import TitleBlock from '../TitleBlock'

const OtherServices = () => {
  return (
    <section className='other-services'>
     <TitleBlock subtitle={'Інші способи подати показники світла через сервіси ТОВ «ЛЬВІВЕНЕРГОЗБУТ» для побутових клієнтів:'} addClass={'pokaz-subtitle'}/>
     <Container>
        <Row className='service-list'>
            <Col md={6} className='d-flex service-list-title'>
            <div className="number"> 01 </div>
                <h3> <Link to={'/page/app'}>У Мобільному застосунку</Link></h3>
            </Col>  
        </Row>
        <Row className='service-list'>
            <Col md={6} className='d-flex service-list-title'>
            <div className="number"> 02 </div>
                <h3> <Link to={'https://my.lez.com.ua/'} target={'_blank'}>У Персональному кабінеті</Link></h3>
            </Col>  
        </Row>
        {/* <Row className='service-list'>
            <Col md={6} className='d-flex service-list-title'>
            <div className="number"> 03 </div>
                <h3>Через чат-боти:</h3>
            </Col>  
        </Row>
        <Row className='other-services__social'>
            <div className="social-qr">
                <img src="/img/viber_qr.png"  alt="viber" />
                <h4>Viber</h4>
            </div>
            <div className="social-qr">
                <img src="/img/telegram_qr.png" alt="telegram" />
                <h4>Telegram</h4>
            </div>
        </Row> */}
     </Container>  
    </section>
  )
}

export default OtherServices