import { Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavigationElement from "./Navigation/NavigationElement";
import { createRef, useRef } from 'react';

const Header = ({sliderContext, handleScroll, setTheme}) => { 
    
    const navigationCompany = createRef();
    const navigationClient = createRef();
    const refHeader = useRef(null);
    const pad = useRef(null);
    const eyePad = useRef(null);

    function changeSlide(id) {
      if(!sliderContext?.destroyed){
        return sliderContext?.slideTo(id);
      }
    }

    function addClasses(ref, className){
      if(className === 'company') {
        ref.current.classList.toggle('active');
        navigationClient.current.classList.remove('active');
        refHeader.current.classList.remove('client');
        refHeader.current.classList.toggle(className);
      }
      if(className === 'client'){
        ref.current.classList.toggle('active');
        navigationCompany.current.classList.remove('active');
        refHeader.current.classList.remove('company');
        refHeader.current.classList.toggle(className);
      }
      pad.current.style.display = "none";
    }

    function removeClasses(){
      navigationCompany.current.classList.remove('active');
      navigationClient.current.classList.remove('active');
      refHeader.current.classList.remove('client');
      refHeader.current.classList.remove('company');
      pad.current.style.display = "none";
    }

    function changeFonts(e,font){
      // change fonts global
      const root = document.querySelector('#root');
      root.style.setProperty('--fontSize', font);

      // change fonts footer
      const prefooter = document.querySelector('.prefooter');
      const allFootLinks = prefooter.querySelectorAll('a');
      for (let i = 0; i< allFootLinks.length; i++) {
        allFootLinks[i].style.fontSize = font;
        allFootLinks[i].style.lineHeight = font;
      }

      //change navigation btn fonts
      const navBtns = [...navigationCompany.current.querySelectorAll('button'), ...navigationClient.current.querySelectorAll('button')];
      for (let i = 0; i< navBtns.length; i++) {
        navBtns[i].style.fontSize = font;
      }
      changeActiveEye(e);
    }

    function changeColor(e,color){ 
      changeActiveEye(e);
      const body = document.querySelector('body');
      if(color === 'black') {
        body.style.background = '#000000';
        setTheme('dark');
      }
      else{
        body.style.background = '#ffffff';
        setTheme('light');
      }
    }

    function changeActiveEye(e) {
      const closeParent = e.target.closest('.eye-font') || e.target.closest('.eye-color'); 
      const allActive = closeParent.querySelectorAll('.active');
      for (let i = 0; i< allActive.length; i++) {
        allActive[i].classList.remove('active');
      }
      e.target.classList.add('active'); 
    }

    function showEyePad(e){
      eyePad.current.classList.add('active');
      document.querySelectorAll('.eye-font-a')[0].classList.add('active');
      document.querySelectorAll('.eye-font-a')[3].classList.add('active'); 

      const body = document.querySelector('body');
      body.style.filter = 'grayscale(1)';
      body.style.background = '#ffffff';
      e.target.closest('.eye-inner').style.display = 'none';
      setTheme('light');
    }

    function closeEyePad(){
      eyePad.current.classList.remove('active');
      const body = document.querySelector('body');
      const root = document.querySelector('#root');
      const prefooter = document.querySelector('.prefooter');

      const allFootLinks = prefooter.querySelectorAll('a');
      for (let i = 0; i< allFootLinks.length; i++) {
        allFootLinks[i].style.fontSize = '12px';
      }

      const allActive = document.querySelectorAll('.eye-font-a');
      for (let i = 0; i< allActive.length; i++) {
        allActive[i].classList.remove('active');
      }
     
      body.style.removeProperty('filter');
      body.style.removeProperty('background');
      root.style.setProperty('--fontSize', '16px');
      document.querySelectorAll('.eye-inner')[1].style.display = 'inline-block';
      setTheme('light');
    }

    return (
      <>
        <div className="eye" ref={eyePad}>
        <Container>
        <div className="eye-panel"> 
          <div className="eye-font">
            <p>Розмір шрифту:</p>
            <div className="eye-font-a active" style={{fontSize: "16px"}} onClick={(e)=>{changeFonts(e,'16px')}}>A</div>
            <div className="eye-font-a" style={{fontSize: "20px"}} onClick={(e)=>{changeFonts(e,'20px')}}>A</div>
            <div className="eye-font-a" style={{fontSize: "24px"}} onClick={(e)=>{changeFonts(e,'24px')}}>A</div>
          </div>
          <div className="eye-color">
            <p>Колір сайту:</p>
            <div className="eye-font-a active" onClick={(e)=>{changeColor(e,'white')}}>A</div>
            <div className="eye-font-a" onClick={(e)=>{changeColor(e,'black')}}>A</div>
          </div>
          <div className="eye-inner" onClick={closeEyePad}>
            <svg className='eye-svg' xmlns="http://www.w3.org/2000/svg" width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0"><path fill="#f9f9f9" d="M 372.125 191.214844 C 268.761719 51.300781 106.332031 51.46875 3.058594 191.214844 C 1.070312 194.117188 1.070312 197.933594 3.058594 200.835938 C 3.804688 201.914062 21.230469 227.546875 52.59375 253.507812 C 136.535156 323.558594 238.699219 323.554688 322.59375 253.507812 C 353.953125 227.546875 371.460938 201.914062 372.125 200.835938 C 374.117188 197.933594 374.117188 194.117188 372.125 191.214844 Z M 187.589844 289.171875 C 98.066406 289.171875 35.835938 215.683594 20.730469 196.023438 C 35.75 176.367188 97.898438 102.878906 187.589844 102.878906 C 277.117188 102.878906 339.347656 176.367188 354.453125 196.023438 C 339.347656 215.683594 277.117188 289.171875 187.589844 289.171875 Z M 187.589844 289.171875 " fill-opacity="1" fill-rule="nonzero"/><path fill="#f9f9f9" d="M 187.589844 120.046875 C 145.6875 120.046875 111.589844 154.140625 111.589844 196.023438 C 115.695312 296.792969 259.503906 296.757812 263.59375 196.023438 C 263.59375 154.140625 229.492188 120.046875 187.589844 120.046875 Z M 187.589844 171.386719 C 173.984375 171.386719 162.949219 182.421875 162.949219 196.023438 C 162.949219 200.75 159.132812 204.566406 154.398438 204.566406 C 149.671875 204.566406 145.855469 200.75 145.855469 196.023438 C 145.855469 173.046875 164.523438 154.304688 187.589844 154.304688 C 198.765625 154.347656 198.78125 171.347656 187.589844 171.386719 Z M 187.589844 171.386719 " fill-opacity="1" fill-rule="nonzero"/>
              </svg>
              <div className="eye-common">
                <p> Звичайна версія сайту</p>
              </div>
            </div>
          </div>
          </Container> 
        </div>
        <header className="header" ref={refHeader}>
          <Container>
          <NavigationElement ref={navigationCompany} handleClass = {addClasses} type={"company"}/>
          <NavigationElement ref={navigationClient} handleClass = {addClasses} type={"client"}/>

            <Row className='align-items-center header__inner'>
              <Col md={2} className="text-start header__icons" >
                <Link className='header__icons-fb' to='https://www.facebook.com/LvivEnergoZbut' target={'_blank'}> <img src='/img/fb.svg' alt=""/></Link>
                <Link className='header__icons-youtube'to='https://www.youtube.com/@lvivenergozbut' target={'_blank'}> <img src='/img/youtube.svg' alt=""/></Link>
                {/* <Link className='header__icons-old' to={'https://old.lez.com.ua/'} target='__blank'>Стара версія сайту</Link> */}
              </Col>
              <Col md={3} className="text-start header__company">
              <svg className='header__company-svg' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M0.256282 11.7489C-0.0854272 11.4142 -0.0854273 10.8715 0.256282 10.5368L4.88756 6L0.256281 1.46323C-0.0854277 1.1285 -0.0854277 0.585786 0.256281 0.251051C0.59799 -0.083684 1.15201 -0.083684 1.49372 0.251051L6.74372 5.39391C7.08543 5.72864 7.08543 6.27136 6.74372 6.60609L1.49372 11.7489C1.15201 12.0837 0.59799 12.0837 0.256282 11.7489Z" fill="#77C043"/>
              </svg>
              <button className='nav-btn company' onClick={()=>{addClasses(navigationCompany,'company'); pad.current.style.display = "block"}}>Для компаній</button>
              </Col>
              <Col md={2} className="text-center logo__inner">
                <Link to='/'><div className="logo"onClick={()=>changeSlide(3)}></div></Link>
              </Col>
              <Col md={3} className="text-end header__company">
               <button className='nav-btn client' onClick={()=>{addClasses(navigationClient,'client'); pad.current.style.display = "block"}}>Для населення </button>
                <svg className='header__company-svg right' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fillRule="evenodd" clipRule="evenodd" d="M0.256282 11.7489C-0.0854272 11.4142 -0.0854273 10.8715 0.256282 10.5368L4.88756 6L0.256281 1.46323C-0.0854277 1.1285 -0.0854277 0.585786 0.256281 0.251051C0.59799 -0.083684 1.15201 -0.083684 1.49372 0.251051L6.74372 5.39391C7.08543 5.72864 7.08543 6.27136 6.74372 6.60609L1.49372 11.7489C1.15201 12.0837 0.59799 12.0837 0.256282 11.7489Z" fill="#77C043"/>
                </svg>
              </Col>
              <Col md={2} className="text-end menu-map">
                <NavLink to={'/search-page'} className={'search-svg'}>
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="73a2d828a9">
                  <path d="M 2.171875 2.171875 L 371 2.171875 L 371 371 L 2.171875 371 Z M 2.171875 2.171875 " clipRule="nonzero"/></clipPath></defs>
                  <path fill="#858A8F" d="M 131.203125 2.171875 C 60.160156 2.171875 2.171875 60.160156 2.171875 131.203125 C 2.171875 202.246094 60.160156 260.234375 131.203125 260.234375 C 163.425781 260.234375 192.863281 248.214844 215.519531 228.550781 L 223.367188 236.398438 L 223.367188 260.234375 L 326.332031 363.199219 C 336.507812 373.371094 353.023438 373.371094 363.199219 363.199219 C 373.371094 353.023438 373.371094 336.507812 363.199219 326.332031 L 260.234375 223.367188 L 236.398438 223.367188 L 228.550781 215.519531 C 248.214844 192.859375 260.234375 163.425781 260.234375 131.203125 C 260.234375 60.160156 202.246094 2.171875 131.203125 2.171875 Z M 131.203125 39.039062 C 182.324219 39.039062 223.367188 80.085938 223.367188 131.203125 C 223.367188 182.324219 182.324219 223.367188 131.203125 223.367188 C 80.085938 223.367188 39.039062 182.324219 39.039062 131.203125 C 39.039062 80.085938 80.085938 39.039062 131.203125 39.039062 Z M 131.203125 39.039062 " 
                  fillOpacity="1" fillRule="nonzero"/></svg>
                </NavLink>
                <svg className='menu-icon-svg' width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleScroll}>
                  <path d="M0 0.995417C0 0.445664 0.470101 0 1.05 0H19.95C20.5299 0 21 0.445664 21 0.995417C21 1.54517 20.5299 1.99083 19.95 1.99083H1.05C0.470101 1.99083 0 1.54517 0 0.995417Z" fill="#858A8F"/>
                  <path d="M0 7C0 6.45025 0.470101 6.00458 1.05 6.00458H19.95C20.5299 6.00458 21 6.45025 21 7C21 7.54975 20.5299 7.99542 19.95 7.99542H1.05C0.470101 7.99542 0 7.54975 0 7Z" fill="#858A8F"/>
                  <path d="M1.05 12.0092C0.470101 12.0092 0 12.4548 0 13.0046C0 13.5543 0.470101 14 1.05 14H19.95C20.5299 14 21 13.5543 21 13.0046C21 12.4548 20.5299 12.0092 19.95 12.0092H1.05Z" fill="#858A8F"/>
                </svg>            
                <div className="eye-inner" onClick={showEyePad}>
                  <svg className='eye-svg' xmlns="http://www.w3.org/2000/svg" width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0"><path fill="#f9f9f9" d="M 372.125 191.214844 C 268.761719 51.300781 106.332031 51.46875 3.058594 191.214844 C 1.070312 194.117188 1.070312 197.933594 3.058594 200.835938 C 3.804688 201.914062 21.230469 227.546875 52.59375 253.507812 C 136.535156 323.558594 238.699219 323.554688 322.59375 253.507812 C 353.953125 227.546875 371.460938 201.914062 372.125 200.835938 C 374.117188 197.933594 374.117188 194.117188 372.125 191.214844 Z M 187.589844 289.171875 C 98.066406 289.171875 35.835938 215.683594 20.730469 196.023438 C 35.75 176.367188 97.898438 102.878906 187.589844 102.878906 C 277.117188 102.878906 339.347656 176.367188 354.453125 196.023438 C 339.347656 215.683594 277.117188 289.171875 187.589844 289.171875 Z M 187.589844 289.171875 " fill-opacity="1" fill-rule="nonzero"/><path fill="#f9f9f9" d="M 187.589844 120.046875 C 145.6875 120.046875 111.589844 154.140625 111.589844 196.023438 C 115.695312 296.792969 259.503906 296.757812 263.59375 196.023438 C 263.59375 154.140625 229.492188 120.046875 187.589844 120.046875 Z M 187.589844 171.386719 C 173.984375 171.386719 162.949219 182.421875 162.949219 196.023438 C 162.949219 200.75 159.132812 204.566406 154.398438 204.566406 C 149.671875 204.566406 145.855469 200.75 145.855469 196.023438 C 145.855469 173.046875 164.523438 154.304688 187.589844 154.304688 C 198.765625 154.347656 198.78125 171.347656 187.589844 171.386719 Z M 187.589844 171.386719 " fill-opacity="1" fill-rule="nonzero"/>
                  </svg>
                  <div className="eye-descr">
                    людям з порушенням зору
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pad" onClick={removeClasses} ref={pad} style={{display: "none"}}></div>
      </>
    );
};

export default Header;