import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Form from 'react-bootstrap/Form';
import { getForm } from '../Axios/forms';
import Loader from './Loader';
import axios from 'axios';
import * as dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import ReactInputMask from 'react-input-mask';

const ApiForm = ({id}) => {

const [loading, SetLoading] = useState(true);
const [formData, SetFormData] = useState({});

const [file, setFile] = useState(false);
const [values, setValues] = useState({});


useEffect(()=>{
    let active = false;
    getForm(SetLoading, id).then((response) =>{
        if(!active) {
            SetFormData(response);
        }
      })

    return () => {
        active = true;
    }; 
},[id])


function setGreenActive (event) {
    const allNum = document.querySelectorAll('.number-form');
    for (let i = 0; i < allNum.length; i++) {
        allNum[i].style['background-color'] = '';
        allNum[i].style['color'] = '#858A8F';
    } // очищаємо всі номера не в фокусі

    const parent = event.target.closest('.green-inner'); // знаходимо найближчий батьківський контейнер
    const currentNum = parent.querySelector('.number-form'); // забираємо номер контейнера
    currentNum.style['background-color'] = '#77C043';
    currentNum.style['color'] = '#FFFFFF';
}

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
};

function handleFileChange(event) {
    setFile(event.target.files[0]);
}

function sendForm (event) {
    event.preventDefault();
    
    const formFiltred = formData.formFields.filter(field => {       
        return  field.type !== 'file';
    })

    const initalNames = formFiltred.map(field => {
        return field.name;
    }).reduce((a, v) => ({ ...a, [v]: ''}), {}); 

    const formAnswerFields = formFiltred.map(field => {
        return {name: field.name, formField: field['@id'], value: values[field.name]};
    })

    const configFile = { headers: { 'content-type': 'multipart/form-data',} }

    const configAll = { headers: { 'content-type': 'application/json', } }

    const article = {
        form: formData['@id'],
        media : [],
        dateEntered: dayjs().format(),
        "view": false,
        formAnswerFields: formAnswerFields,
    }
    
    // console.log(initalNames);
   
    if(file) {
        const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
        const formFile = new FormData();
        formFile.append('file', file);
        formFile.append('fileName', file.name);

        axios.post(url, formFile, configFile).then((response) => {

            const articleFile = {...article, media: [`${response.data['@id']}`]}

            axios.post(process.env.REACT_APP_SERVER_URL  + '/api/form_answers', articleFile, configAll)
                .then(() => {
                    setValues(initalNames);
                    setFile(false);
                    toast.success("Дані відправлено в обробку!");
                })
                .catch(() => {
                    setValues(initalNames);
                    setFile(false);
                    toast.error("Помилка при відправці даних");
            });
        }); 
    }
    else {        
        axios.post(process.env.REACT_APP_SERVER_URL  + '/api/form_answers', article, configAll)
            .then(() => {
                setValues(initalNames);
                setFile(false);
                toast.success("Дані відправлено в обробку!");
            })
            .catch(() => {
                setValues(initalNames);
                setFile(false);
                toast.error("Помилка при відправці даних");
        });
    }
};


return (
    loading 
    ? <Loader/>
    : <section className="green application-form">
      <Container>
        <Row>
        <Col md={12}>
                <Row>
                    <Col md={6} className='h2-green'>
                        <h3>{formData.name}</h3>
                        <p>{formData.description}</p>
                    </Col>
                </Row>
            </Col>
            <div className="application-form__inner">
            <div className="application-form__inner-gray">
                <form onSubmit={sendForm} type='submit'>
                {formData.formFields && formData.formFields.map((field, index) => {
                    return  field.deleted !== true &&
                            <div className="green-inner" key={field.id} onFocus={setGreenActive}>
                                <Col md={6} className= "header-green">
                                    <div className="form-left d-flex align-items-center height0">
                                        <span className="number-form">{index < 9 ? "0" + (index + 1) : index + 1}</span>
                                        <span className="number-text">{field.label}</span> 
                                    </div>
                                </Col>
                                <Col md={6} className= "header-green">
                                {field.type === 'textarea' 
                                    ? <textarea onChange={handleInputChange} value={values[field.name]} name={field.name} className="form-control" placeholder={field.defaultValue} aria-label="default textarea" style={{minHeight:"144px"}} required = {field.required !== undefined ? field.required : false}/>                                   
                                    : <><ReactInputMask 
                                            mask={field.type === 'tel' ? '(+380)99 9999 999' : ''} 
                                            onChange={field.type === 'file' ? handleFileChange : handleInputChange} 
                                            value= {values[field.name]}
                                            name={field.name}
                                            type={field.type}
                                            placeholder={field.defaultValue} 
                                            className='form-control'
                                            required = {field.required !== undefined ? field.required : false}
                                            >
                                        </ReactInputMask>
                                        {field.type === 'file' && <p className="grey-text">
                                            Ви можете додати файли таких форматів: doc, docx, xls, xlsx, png, tiff, jpg, jpeg, gif, pdf, txt. 
                                            Розмір файлу не повинен бути більшим як 20 МБ.</p> }
                                        </>
                                }
                                </Col>
                            </div>
                }) }

            <div className="green-inner" onFocus={setGreenActive}>
                <Col md={6} className= "header-green">
                    <div className="form-left d-flex align-items-center height0">
                        <span className="number-form">{formData?.formFields?.length < 9 ? "0" + (formData?.formFields?.length  + 1) : formData?.formFields?.length  + 1}</span> 
                        <span className="number-text">Надати згоду</span>
                    </div>
                </Col>
                <Col md={6} className= "header-green">
                   <Form.Check
                        type={'checkbox'}
                        id={`default-checkbox}`}
                        label={`Надаю згоду ТОВ «Львівенергозбут» на автоматизовану обробку моїх персональних даних, згідно із чинним законодавством, та можливу їх передачу третім особам, які мають право на отримання цих даних.`}
                        required
                    />
                </Col>
            </div>
            <div className="green-inner">
            <Col md={6} className= "header-green">
                    <p className="text-left fs-12">Поле помічене зірочкою «*»<br/> обов’язкове для заповнення</p>
                </Col>
            <Col md={6} className= "header-green">  
                <button type="submit" className="btn-submit" >Надіслати</button>                  
            </Col>
            </div>
            </form>
           </div>
          </div>
        </Row>
    </Container>
        <ToastContainer /> 
    </section>
  )
}

export default ApiForm