// import GreenForm from "../../components/GreenForm";
import TitleBlock from "../../components/TitleBlock";
// import YellowContact from "../../components/YellowContact";
import SliderNews from "../../components/News/SliderNews";
import { useEffect } from "react";
import PickTsok from "../../components/PickTsok";
import { useOutletContext } from "react-router-dom";

const CenterObslug = ({type}) => {

  // const optionsActivity = ['ТОВ','ПАТ','ПрАТ', 'ГО', 'ФОП', 'ПП','ТОВ','ТзОВ','ФО', 'Освіта','Наука', 'Медицина','ОСББ', 'Дачні', 'КП','Кооперативи','Гаражні','Інше'];
  // const optionsСommerce = [
  //   {id: 31, type: 'Комерційна пропозіцїя №5/2 Тарифний план «Базовий з тарифом ОСР» Оплата плановими платежами'},
  //   {id: 9, type: 'Комерційна пропозіцїя №4/1 Тарифний план «Базовий з тарифом ОСР» Оплата не плановими платежами'},
  //   {id: 11, type: 'Комерційна пропозіцїя №3/5 Тарифний план «Базовий з тарифом ОСР» Оплата плановими платежами'},
  //   {id: 7, type: 'Комерційна пропозіцїя №7/1 Тарифний план «Базовий з тарифом ОСР» Оплата не плановими платежами'},
  // ];

  const [,,theme] = useOutletContext();
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

    return (
       <>
         {theme === 'light' && <div className="mont-block"></div> }
         <TitleBlock title={'Центри обслуговування клієнтів'} type={type}/>
         {/* <YellowContact/> */}
         <PickTsok type={type}/>
         {/* <GreenForm optionsActivity={optionsActivity} optionsСommerce={optionsСommerce}/> */}
         <SliderNews/>
      </>
    );
};


export default CenterObslug;