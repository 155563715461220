import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AccordionInfoComp = () => {

  return (
      <Container className='accordion-info'>
        <Row>
          <Col>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey={1}>
                <Accordion.Header><span>01</span>Які споживачі мають право на універсальну послугу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Право на універсальну послугу мають виключно побутові та малі непобутові  споживачі.</p>
                    <p>Згідно п. 1.1.2. ПРРЕЕ побутовий споживач - індивідуальний побутовий споживач (фізична особа,  яка використовує електричну енергію для забезпечення власних побутових потреб, що не  включають професійну та/або господарську діяльність) або колективний побутовий 
                        споживач (юридична особа, створена шляхом об'єднання фізичних осіб - побутових споживачів, яка  розраховується за електричну енергію за показами загального розрахункового засобу обліку в  обсязі електричної енергії, спожитої для забезпечення власних побутових потреб таких фізичних  осіб, що не включають професійну та/або господарську діяльність).</p>
                    <p>Згідно п. 1.1.2. ПРРЕЕ малий непобутовий споживач - споживач, який не є побутовим споживачем  і купує електричну енергію для власного споживання, електроустановки якого приєднані до  електричних мереж з договірною потужністю до <b>50 кВт (включно)</b>.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={2}>
                <Accordion.Header><span>02</span>Як формується ціна на універсальну послугу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Розрахунок та фомування ціни на універсальні послуги для малих непобутових споживачів  проводиться у відповідності до Порядку формування цін на універсальні послуги затвердженого  постановою НКРЕКП від 05.10.2018р.№1177 (із змінами).</p>
                    <p>Ціна на універсальні послуги включає ціну купівлі електричної енергії на ринку електричної  енергії, тариф на послуги постачальника універсальних послуг, тариф на послуги оператора  системи передачі, оператора системи розподілу на відповідному класі напруги.</p>
                    <ul>
                        <li>Ціна закупівлі електричної енергії на ринку електричної енергії визначається, як  прогнозована ціна закупівлі на розрахунковий місяць з врахуванням відхилення величини сплати  за куповану електричну енергію за попередній період, та розраховується за формулою вказаною в  Порядку формування цін на універсальні послуги затвердженого постановою НКРЕКП від  05.10.2018р.№1177 (із змінами).</li>
                        <li>Тариф на послуги постачальника універсальних послуг розрахований у відповідності до  Методики розрахунку тарифу на послуги постачальника універсальних послуг затвердженої  постановою НКРЕКП від 05.10.2018рю№1176 та затверджується постановою НКРЕКП.</li>
                        <li>Тариф на послуги з передачі електричної енергії, встановлюється НКРЕКП.</li>
                        <li>Тариф на послуги з розподілу електричної енергії, встановлюється НКРЕКП.</li>
                    </ul>
                    <p>Ціна на універсальні послуги формується на розрахунковий місяць і оприлюднюється на  офіційному вебсайті Компанії в мережі Інтернет та на інформаційних стендах у центрах  обслуговування клієнтів.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={3}>
                <Accordion.Header><span>03</span>Як формується ціна для клієнтів вільної ціни?</Accordion.Header>
                  <Accordion.Body>
                    <p> Ціна для споживачів, що розраховуються за вільними цінами розраховується щомісячно, по  завершенню розрахункового місяця і формується з наступних складових:</p>
                    <ul>
                        <li>Ціна електричної енергії що склалась на всіх сегментах ринку електричної енергії з  врахуванням приналежності до об’єднаної Енергетичної Сиситеми або Бурштинського острова.</li>
                        <li>Маржа постачальника, що включає затрати Компанії на послуги постачання електричної  енергії.</li>
                        <li>Тариф на послуги з передачі електричної енергії, встановлюється НКРЕКП.</li>
                        <li>Тариф на послуги з розподілу електричної енергії, встановлюється НКРЕКП ( застосовується  у випадку здійснення споживачем плати за послуги з розподілу Оператору розподілу , через  постачальника).</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey={4}>
                <Accordion.Header><span>04</span>Які споживачі мають право на універсальну послугу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Право на універсальну послугу мають виключно побутові та малі непобутові  споживачі.</p>
                    <p>Згідно п. 1.1.2. ПРРЕЕ побутовий споживач - індивідуальний побутовий споживач (фізична особа,  яка використовує електричну енергію для забезпечення власних побутових потреб, що не  включають професійну та/або господарську діяльність) або колективний побутовий споживач  (юридична особа, створена шляхом об'єднання фізичних осіб - побутових споживачів, яка  розраховується за електричну енергію за показами загального розрахункового засобу обліку в  обсязі електричної енергії, спожитої для забезпечення власних побутових потреб таких фізичних  осіб, що не включають професійну та/або господарську діяльність).</p>
                    <p>Згідно п. 1.1.2. ПРРЕЕ малий непобутовий споживач - споживач, який не є побутовим споживачем  і купує електричну енергію для власного споживання, електроустановки якого приєднані до  електричних мереж з договірною потужністю до <b>50 кВт (включно)</b>.</p>
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey={4}>
                <Accordion.Header><span>04</span>Яка різниця між універсальною послугою та постачанням електричної енергії за вільними  цінами?</Accordion.Header>
                  <Accordion.Body>
                    <p>Універсальна послуга повинна надаватися виключно споживачам, які мають право на  отримання універсальної послуги, а саме побутовим та малим непобутовим споживачам.  Постачання електричної енергії здійснюється на підставі договору про постачання електричної  енергії постачальником універсальних послуг. Ціна на універсальну послугу визначається  відповідно до методики (порядку), затвердженої НКРЕКП.</p>
                    <p>Натомість, постачання електричної енергії за вільними цінами здійснюється  споживачам, електроустановки якого приєднані до електричних мереж з договірною  потужністю <b>понад 50 кВт</b>.</p>
                    <p>Постачання електричної енергії споживачам вільної ціни здійснюється на підставі договору про  постачання електричної енергії споживачу.</p>
                    <p>На відміну від ціни універсальної послуги, яка регулюється державою, ціна споживачам вільної  ціни встановлюється за домовленістю споживача та постачальника у договорі</p>   
                </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={5}>
                <Accordion.Header><span>05</span>Чи може споживач універсальної послуги перейти на постачання електричної енергії за  вільними цінами?</Accordion.Header>
                  <Accordion.Body>
                    <p>Будь-який споживач універсальної послуги має право на постачання електричної  енергії за вільними цінами на підставі укладеного договору.</p>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={6}>
                <Accordion.Header><span>06</span>Чи може споживач універсальної послуги, який перейшов на постачання електричної енергії  за вільними цінами, перейти знову на постачання електричної енергії на умовах  універсальної послуги?</Accordion.Header>
                  <Accordion.Body>
                    <p>Будь-який споживач універсальної послуги, який перейшов на постачання електричної  енергії за вільними цінами, має право знову перейти на постачання електричної енергії на умовах  універсальної послуги після розірвання договору про постачання електричної енергії споживачу.</p>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={7}>
                <Accordion.Header><span>07</span>Що є підставою для укладення електропостачальником договору із новим споживачем?</Accordion.Header>
                  <Accordion.Body>
                    <p>Підставою для укладення електропостачальником відповідного договору із новим  споживачем є подання споживачем заяви-приєднання до відповідного договору разом з пакетом  документів, які передбачені в п. 3.2.11., 3.3.5. ПРРЕЕ.</p>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={8}>
                <Accordion.Header><span>08</span>Як обчислюються строки на оплату?</Accordion.Header>
                  <Accordion.Body>
                    <p>Відповідно до ст. 253 Цивільного кодексу України строк оплати рахунку рахується з  наступного дня після дати вручення рахунку, при цьому день вручення рахунку не рахується. Тобто всі строки рахуються з наступного дня.</p>
                    <p>Наприклад, якщо споживач отримав рахунок 17.05.2021 р., термін оплати згідно рахунку – 5  робочих днів, то обов’язок щодо сплати рахунку виникає у споживача не 17.05.2021 р., цей день не  рахується, а виникає з 18.05.2021 р. (перший робочий день після дня отримання рахунку), а  кінцевий строк оплати рахунку – 24.05.2021 р. (включно) (п’ятий робочий день). У такий спосіб рахуються всі строки.</p>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={9}>
                <Accordion.Header><span>09</span>Хто визначає обсяг електричної енергії, який спожитий споживачем за відповідний  розрахунковий період?</Accordion.Header>
                  <Accordion.Body>
                    <p>Обсяг електричної енергії, який спожитий споживачем за відповідний розрахунковий  період, визначається ОСР, такі дані ОСР передає електропостачальнику для проведення  розрахунків між електропостачальником та споживачем.</p>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={10}>
                <Accordion.Header><span>10</span>Які є форми оплат за електричну енергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Відповідно до п. 4.7. ПРРЕЕ, ст. 276 Господарського кодексу України є такі форми  оплати за електричну енергію:</p>
                    <ul>
                        <li>здійснення планових платежів з наступним перерахунком, що проводиться за фактично  відпущену електричну енергію. Відповідно до п. 1.1.2. ПРРЕЕ планові платежі – перерахування  коштів частинами з певною періодичністю протягом розрахункового періоду; </li>
                        <li>здійснення попередньої оплати з остаточним перерахунком, що проводиться за фактично  відпущену електричну енергію. Відповідно до п. 1.1.2. ПРРЕЕ попередня оплата – оплата до  початку розрахункового періоду повної вартості прогнозованого обсягу споживання електричної  енергії у найближчому наступному періоді; </li>
                        <li>оплата за фактично відпущену електричну енергію. </li>
                    </ul>
                    <p>Різниця між такою формою оплати як «планові платежі» та «попередня оплата» полягає у тому,  що при здійсненні планових платежів споживач сплачує вартість договірного обсягу за поточний  розрахунковий період у поточному розрахунковому періоді (тобто у травні платить за травень), а  при попередній оплаті споживач оплачує вартість договірного обсягу на наступний розрахунковий  період до початку такого розрахункового періоду (у квітні платить за травень).</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={11}>
                <Accordion.Header><span>11</span>Які є підстави у електропостачальника для припинення електропостачання споживачу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Відповідно до абз. 2 п. 7.5. ПРРЕЕ у електропостачальника є такі підстави для  припинення електропостачання споживачу:</p>
                    <ul>
                        <li>заборгованість з оплати за спожиту електричну енергію відповідно до умов договору з  електропостачальником;</li>
                        <li>недопущення уповноважених представників електропостачальника до розрахункових  засобів комерційного обліку електричної енергії, що розташовані на території споживача. </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={12}>
                <Accordion.Header><span>12</span>Коли у електропостачальника виникає право на припинення електропостачання споживача  з підстави наявності заборгованості за електричну енергію?</Accordion.Header>
                  <Accordion.Body>
                        <p>Відповідно до абз. 4 п. 7.5. ПРРЕЕ право на припинення електропостачання споживачу  з підстави наявності заборгованості за електричну енергію виникає у електропостачальника  виключно після встановлення факту неоплати за електроенергію згідно умов договору. Неоплачена вартість електричної енергії вважається боргом споживача на наступний день після  кінцевого строку оплати рахунку.</p>
                        <p>Наприклад, споживач мав оплатити рахунок на суму 1000 грн. до 17.05.2021 р. (включно). З 18.05.2021 р. 1000 грн. є заборгованістю споживача перед електропостачальником, якщо  споживач до 17.05.2021 р. (включно) не оплатив рахунок електропостачальника. Відповідно до п. 4.4. ПРРЕЕ датою здійснення оплати за виставленим платіжним документом є  дата, на яку оплачена сума коштів зараховується на поточний рахунок із спеціальним режимом  використання електропостачальника.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={13}>
                <Accordion.Header><span>13</span>Протягом якого строку відновлюється електропостачання споживачу після сплати ним  боргу? </Accordion.Header>
                  <Accordion.Body>
                    <p>Відповідно до п. 7.12. ПРРЕЕ відновлення електроживлення електроустановок  споживача, які були відключені ОСР з підстави наявної заборгованості споживача перед  електропостачальником, здійснюється ОСР протягом 3 робочих днів у містах та 5 робочих днів у  сільській місцевості після отримання від електропостачальника інформації про сплату споживачем  боргу.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={14}>
                <Accordion.Header><span>14</span>Який державний орган врегульовує взаємовідносини між Постачальником електричної  енергії та Споживачем? </Accordion.Header>
                  <Accordion.Body>
                    <p>Національна комісія що здійснює державне регулювання у сферах енергетики та  комунальних послуг (НКРЕКП).</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={15}>
                <Accordion.Header><span>15</span>Хто виконує роль оператора системи передачі?</Accordion.Header>
                  <Accordion.Body>
                    <p>ПрАТ "НЕК "УКРЕНЕРГО".</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={16}>
                <Accordion.Header><span>16</span>Як споживач електричної енергії може оплачувати послуги оператора системи розподілу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Споживач може здійснювати плату за послугу з розподілу електричної енергії через  Постачальника з наступним переведенням цієї оплати Постачальником оператору системи  розподілу або безпосередньо напряму оператору системи розподілу.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={17}>
                <Accordion.Header><span>17</span>За скільки днів Постачальник електричної енергії має повідомити Споживача про зміну  ціни або інших умов договору?</Accordion.Header>
                  <Accordion.Body>
                    <p>За 20 днів до очікуваної дати.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={18}>
                <Accordion.Header><span>18</span>За скільки днів Постачальник природного газу має повідомити Споживача про зміну ціни  або інших умов договору?</Accordion.Header>
                  <Accordion.Body>
                    <p>За 30 днів до очікуваної дати.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={19}>
                <Accordion.Header><span>19</span>Як споживач природного газу може оплачувати за послуги доставки природного газу  Оператору ГРМ (Облгаз)?</Accordion.Header>
                  <Accordion.Body>
                    <p>Виключно самостійно напряму Оператору ГРМ.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={20}>
                <Accordion.Header><span>20</span>Хто виконує роль оператора газосховищ?</Accordion.Header>
                  <Accordion.Body>
                    <p>АТ "Укртрансгаз".</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={21}>
                <Accordion.Header><span>21</span>Хто адмініструє обмін інформації між Постачальниками природного газу та Операторами  ГРМ на території всієї України?</Accordion.Header>
                  <Accordion.Body>
                    <p>ТОВ "Оператор газотранспортної системи України".</p>
                  </Accordion.Body>
                </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
  )
}

export default AccordionInfoComp