import { AxiosInstance } from "./axios";

export const getForm = async (isLoading, id)=>{
    isLoading(true);
    const form = await AxiosInstance.get(`/api/forms/${id}`)
    isLoading(false);
    return form.data;
}

export const getAbonPhoneName = async (persuid)=>{
    const form = await AxiosInstance.get(`/api/lep_abon_phone_mails?pagination=false&persuid=${persuid}`)
    return form.data;
}