
import TitleBlock from "../components/TitleBlock";
import Container from 'react-bootstrap/Container';
import SliderNews from "../components/News/SliderNews";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect} from "react";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import * as DOMPurify from 'dompurify';
import Loader from "../components/Loader";
// import { clearContent } from "../utils/news";
import { getPage } from "../Axios/getPage";
// import PickTsok from "../components/PickTsok";
import ApiForm from "../components/ApiForm";

const ApiContentPage = () => {
  
const param = useParams();
const [pageItem, SetPageItem] = useState({});
const [pageContent, SetStateContent] = useState({before: '', after: ''})
const [loading, SetLoading] = useState(true);
const [formId, SetFormId] = useState(0);
const [,,theme] = useOutletContext();
const navigate = useNavigate();

useEffect (()=>{
    window.scrollTo(0,0);
    let active = false;
    const idRegex = /\[(\d+)\]/;
    const lezLink = /https:\/\/lez\.com\.ua\/uploads\/|\/uploads\//g;
    const replaceLink = process.env.REACT_APP_SERVER_URL + "/media/direct/";

    getPage(SetLoading, param.id).then((response)=>{
      const [data] = response['hydra:member'];
      const pageContent =  data && data.content;
      const pageItem = {...data, content: pageContent?.replace(lezLink, replaceLink)}

      const match = pageItem?.content?.match(idRegex);

      if (match && match[1]){
        const id = parseInt(match[1], 10);
        SetStateContent({...pageContent, before: pageItem.content.split(`[${id}]`)[0], after: pageItem.content.split(`[${id}]`)[1]})
        SetFormId(match[1]);
      } else {
        SetFormId(0);
      }
      
      if(data !== undefined && data.active === 'on') {
        !active && SetPageItem(pageItem);
      }
      else {
        navigate('/no-page', {state:{id:param.id}});
      }
    });

    return () => {
      active = true;
    };
    
  },[param.id, navigate])

  return (
    loading 
    ? <Loader/>
    : <section className="page">
        {theme === 'light' && <div className="mont-block"></div> }
        {pageItem.content &&
        <>
        <TitleBlock title={pageItem.name} type={pageItem?.type}></TitleBlock>
        <Container className="page__container">
           <Row className="page__inner">
               <Col>
                 <div className='line' style={{marginBottom:"30px"}}></div>
                  {pageItem.images && 
                  <img src={process.env.REACT_APP_SERVER_URL + pageItem.images} alt="page-img" style={{maxWidth: "100%"}}/>}
                  {formId ? <>
                  <div className={'content '+ param.id} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageContent.before, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div>
                  <ApiForm id = {formId}/>
                  <div className={'content '+ param.id} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageContent.after, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div></>
                  : <div className={'content '+ param.id} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageItem.content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div>
                  }
               </Col>
           </Row>
        </Container>
        </>}
        {/* {param.id === "info" && 
        <PickTsok/> 
        } */}
        {/* {param.id === 'contacts' && 
        <ApplicationForm/>
        } */} 
        <SliderNews/>
    </section> 
  )
}

export default ApiContentPage