import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import PaymentDescr from "../../components/Payment/PaymentDescr";

const PaymentCom = ({type}) => {

  useEffect(() => {
        window.scrollTo(0,0);
  }, [])

  const [,,theme] = useOutletContext();

  return (
    <>
      {theme === 'light' && <div className="mont-block"></div> }
      <section className='payment-comp'>
      <TitleBlock title={'Оплата'} type={'для компаній'}/>
        <Container>
            <Row>
               <Col md={6}>
                    <h4>Юридичні споживачі ТОВ «Львівенергозбут» можуть  отримати рахунок за спожиту електроенергію у будь-якому Центрі обслуговування клієнтів, через 
                    Персональний кабінет, через онлайн-звернення, звернушись на пошту або за телефоном.</h4>
                </Col> 
            </Row>
            <Row className="info">
                <Col md={12} className="info__content">
                    <p>Розрахунки споживача за електричну енергію повинні здійснюватись в терміни, визначені відповідно до обраної споживачем комерційної 
                        пропозиції або умов договору, на реквізити, вказані у платіжному документі (рахунку), в тому числі у разі їх зміни.</p>
                </Col>
            </Row>
        </Container> 
      </section>
      <PaymentDescr/>
      <SliderNews/>
    </>
  )
}

export default PaymentCom