import React from 'react'
import { Link } from 'react-router-dom'

const PaymentItem = ({type, isCommision, path}) => {
  return (
    <div className='online-item'>
      {path ?
        <Link className='online-item-payment' to={path} target="_blank">
        {type}
        {isCommision 
        ? <p className='payment-comission active'>Можлива комісія</p>
        : <p className='payment-comission'>Без комісії</p>
        }
        </Link>
      : <div className='online-item-payment'>
          {type}
          {isCommision 
          ? <p className='payment-comission active'>Можлива комісія</p>
          : <p className='payment-comission'>Без комісії</p>
          }
        </div>
      }

    </div>
  )
}

export default PaymentItem