import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { getPage } from '../Axios/getPage'
import Eaccount from '../components/Eaccount/Eaccount'
import Loader from '../components/Loader'
import TitleBlock from '../components/TitleBlock'
import * as DOMPurify from 'dompurify'
import SliderNews from '../components/News/SliderNews'

const AccountPage = () => {
  const [loading, setLoading] = useState(true);
  const [pageContent, setPageContent] = useState({content: '', title: ''})

  useEffect (()=>{
    window.scrollTo(0,0);
    let active = false;
    getPage(setLoading, 'e_account').then((response)=>{
      const [data] = response['hydra:member'];
      if(!active) {
        setPageContent({content: data?.content, title: data?.name})
      }
    })

    return () => {
      active = true;
    };

  },[]);
  
  return (
    loading 
    ? <Loader/>
    : <section className="page account">
      <TitleBlock title={pageContent.title}></TitleBlock>
      <Container>
      <div className='line' style={{marginBottom:"30px"}}></div>
        <div className="static-content">
          <p>Нова послуга для наших клієнтів!</p>
          <p>Відтепер рахунок за електроенергію Ви можете отримати на електронну пошту, а інформацію про нарахування за світло — SMS - повідомленням на Ваш телефон.</p>
        </div>
      </Container>
      <Eaccount />
      <Container className="page__container">
           <Row className="page__inner">
               <Col>
                 <div className='content' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageContent.content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div>
               </Col>
           </Row>
        </Container>
        <SliderNews/>
    </section>
  )
}

export default AccountPage