import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const AccordionInfoCli = () => {

  return (
      <Container className='accordion-info'>
        <Row>
          <Col>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey={1}>
                <Accordion.Header><span>01</span>Як формується ціна за спожиту електроенергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Фіксовані ціни на електричну енергію для побутових споживачів затверджені Постановою Кабінету Міністрів України від 05.06.2019 р. 
                      № 483 «Про затвердження Положення про покладення спеціальних обов’язків на учасників ринку електричної енергії для забезпечення загальносуспільних 
                      інтересів у процесі функціонування ринку електричної енергії» (Додаток 3 до «Положення про покладення спеціальних обов’язків на учасників ринку електричної
                      енергії для забезпечення загальносуспільних інтересів у процесі функціонування ринку електричної енергії») із наступними змінами та доповненнями.</p>
                    <p>Ознайомитись із фіксованими цінами на електричну енергію для побутових споживачів  Ви можете <Link to={'/clients/tarif'}>тут</Link></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={2}>
                <Accordion.Header><span>02</span>Як отримати інформацію про нарахування за спожиту електричну енергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Ознайомитись із нарахуванням за спожиту електроенергію можна у будь-який зручний для споживача спосіб:</p>
                     <ul>
                     <li>через офіційний вебсайт ТОВ «Львівенергозбут» на сторінці <Link to= {'https://my.lez.com.ua/'} target={'_blank'}>«Персональний кабінет»</Link>, попередньо здійснивши реєстрацію;</li>
                      <li>за допомогою <Link to= {'/page/app'}>мобільного застосунку</Link> ТОВ «Львівенергозбут» попередньо здійснивши реєстрацію;</li>
                      <li>у чат-боті Viber або Telegram;</li>
                      <li>через Голосове меню без з’єднання з оператором за телефонами <Link to={'/page/info'}>Кол-центру</Link> ТОВ «Львівенергозбут» – 
                      <Link to={'tel:0676370568'}> 067-637-05-68;</Link> <Link to={'tel:0506370568'}>050-637-05-68;</Link> <Link to={'tel:0322420568'}>032-242-05-68;</Link> <Link to={'tel:0736370568'}>073-637-05-68;</Link></li>
                      <li>в <Link to={'/page/ikc'}>інформаційно-консультаційному центрі</Link> ТОВ «Львівенергозбут» (м. Львів, вул. Шевченка, 1, тел.: <Link to={'tel:0322595130'}>(032) 259-51-30</Link>);</li>
                      <li>в <Link to= {'/clients/center-obslug'}>центрі обслуговування клієнтів</Link> ТОВ «Львівенергозбут».</li>
                     </ul>
                    <p>Отримати рахунок за спожиту електричну енергію на електронну пошту, а інформацію про нарахування – SMS-повідомленням на Ваш телефон Ви можете <Link to={'/page/e_account'}>тут</Link></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={3}>
                <Accordion.Header><span>03</span>Як скорегувати нарахування за спожиту електричну енергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Згідно п. 4.12. ПРРЕЕ: розрахунки між споживачем та електропостачальником здійснюється згідно з даними, отриманими від адміністратора комерційного 
                      обліку в порядку, передбаченому Кодексом комерційного обліку, затвердженого постановою Національної комісії, що здійснює державне регулювання у сферах 
                      енергетики та комунальних послуг 14.03.2018 року № 311 із змінами і доповненнями, про обсяги поставленої, розподіленої (переданої) та купленої 
                      електричної енергії.</p>
                    <p>Відповідно до п. 10 Постанови Національної комісії, що здійснює державне регулювання у сферах енергетики та комунальних послуг від 14.03.2018 р. 
                      № 312 «Про затвердження правил роздрібного ринку електричної енергії», функції адміністратора комерційного обліку на роздрібному ринку електричної 
                      енергії виконує відповідний оператор системи розподілу (далі – ОСР).</p>
                    <p>Відповідно до п. 4.13. ПРРЕЕ, для здійснення розрахунків за фактично спожиту електричну енергію електропостачальник має сформувати та виставити споживачу 
                      платіжний документ на підставі даних комерційного обліку, отриманих у порядку, передбаченому Кодексом комерційного обліку</p>
                    <p>ТОВ «Львівенергозбут» виставляє рахунки за спожиту електричну енергію на підставі даних комерційного обліку, отриманих від оператора системи розподілу.</p>
                    <p>У разі виникнення у споживача сумніву у правильності показів засобів вимірювальної техніки та/або обсягів споживання електричної енергії, на підставі 
                      яких здійснювались нарахування у пред’явленому до оплати документі, споживач подає про це заяву оператору системи/постачальнику послуг комерційного обліку, 
                      а у разі виникнення сумніву у правильності суми у пред’явленому до оплати документі щодо оплати за постачання або розподіл (передачу) електричної енергії - 
                      учаснику роздрібного ринку, який надав розрахунковий документ</p>
                    <p>Учасник ринку протягом 5 робочих днів від дня одержання заяви перевіряє документ (рахунок) щодо оплати електричної енергії, послуг з розподілу (передачі), 
                      комерційного обліку електричної енергії, компенсації перетікань реактивної електричної енергії тощо, у разі необхідності звертається в установленому <Link to={'https://zakon.rada.gov.ua/laws/show/v0311874-18#n9'} target='_blank'>Кодексом комерційного обліку</Link> порядку 
                      щодо перевірки даних комерційного обліку.</p>
                    <p>На підставі отриманих за результатами перевірки даних комерційного обліку електропостачальник, оператор системи та постачальник послуг комерційного обліку за необхідності проводять відповідні коригування.</p>
                    <p>За результатами перевірки учасник роздрібного ринку повідомляє споживача.</p>
                    <Link to={'/clients/center-obslug'}>Центри обслуговування клієнтів ТОВ «Львівенергозбут»</Link>
                    <Link to={'/page/info'} style={{display:"block"}}>Контактна інформація операторів системи розподілу.</Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={4}>
                <Accordion.Header><span>04</span>Як укласти (переукласти) договір про постачання електричної енергії постачальником універсальних послуг?</Accordion.Header>
                  <Accordion.Body>
                    <p>Укладення споживачем договору про постачання електричної енергії постачальником універсальних послуг відбувається шляхом приєднання 
                      споживача до договору на умовах обраної споживачем комерційної пропозиції, для чого <b>споживач подає такому електропостачальнику заяву-приєднання 
                      та інші документи. повний перелік на сторінці</b> <Link to={'/clients/contract'}>Укласти договір</Link>.</p>
                    <p><b>Для отримання паспорта точки розподілу/передачі об’єкта, (площадки вимірювання) необхідно звернутись до оператора системи розподілу.</b></p>
                    <p>Згідно з п. 13.7 публічного договору про постачання електричної енергії постачальником універсальних послуг, у разі якщо об'єкт Споживача 
                        перебуває у власності (користуванні) кількох осіб, <b>укладається один договір з одним із співвласників (користувачів) за умови письмової згоди всіх інших співвласників</b> (користувачів), про що робиться відмітка в цьому Договорі.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={5}>
                <Accordion.Header><span>05</span>Як розірвати договір про постачання електричної енергії постачальником універсальних послуг?</Accordion.Header>
                  <Accordion.Body>
                    <p>Згідно п. 4.27 ПРРЕЕ, у разі звільнення приміщення та/або остаточного припинення користування електричною енергією споживач зобов'язаний повідомити 
                      електропостачальника та оператора системи або основного споживача про намір припинити дію відповідних договорів не пізніше ніж за 20 робочих днів до 
                      дня звільнення приміщення та/або остаточного припинення користування електричною енергією та надати заяву щодо розірвання договорів і в цей самий термін 
                      здійснити сплату всіх видів платежів, передбачених відповідними договорами, до заявленого споживачем дня звільнення приміщення та/або остаточного 
                      припинення користування електричною енергією включно.</p>
                    <p>Дія договору про постачання електричної енергії достроково припиняється у разі отримання електропостачальником від оператора системи або нового чи 
                      попереднього власника (користувача) об’єкта споживача документального підтвердження факту укладення договору споживача про надання послуг з розподілу 
                      (передачі) електричної енергії між оператором системи і новим власником (користувачем) цього об'єкта.</p>
                    <p>Для розірвання договору про надання послуг з розподілу електричної енергії, <b>Вам необхідно звернутись до Вашого оператора системи розподілу.</b></p>
                    <Link to={'/clients/center-obslug'}>Центри обслуговування клієнтів ТОВ «Львівенергозбут»</Link>
                    <Link to={'/page/info'} style={{display:"block"}}>Контактна інформація операторів системи розподілу.</Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={6}>
                <Accordion.Header><span>06</span>Як розтермінувати заборгованість за спожиту електричну енергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Згідно з п. 4.24 ПРРЕЕ, у разі виникнення у споживача заборгованості з оплати за спожиту електричну енергію сторони за взаємною згодою укладають 
                      договір щодо реструктуризації заборгованості та оформлюють графік погашення заборгованості, який є додатком до договору.</p>
                    <p>Укладення сторонами та дотримання споживачем узгодженого графіка погашення заборгованості не звільняє споживача від оплати поточного споживання електричної енергії.</p>
                    <p>Для розтермінування заборгованості за спожиту електричну енергію необхідно звернутися в центр обслуговування клієнтів з <b>письмовою заявою про розтермінування заборгованості та надати:</b></p>
                    <ul>
                      <li>довідку про доходи за останні 6 місяців;</li>
                      <li>копію паспорта та ідентифікаційного номера.</li>
                    </ul>
                    <Link to={'/clients/center-obslug'}>Центри обслуговування клієнтів ТОВ «Львівенергозбут»</Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={7}>
                <Accordion.Header><span>07</span>Коли необхідно подавати покази електролічильника?</Accordion.Header>
                  <Accordion.Body>
                    <p>Побутовим клієнтам покази електролічильника необхідно подавати щомісяця у період, що починається <b>за два календарні дні 
                      до кінця розрахункового місяця</b> та закінчується на третій календарний день наступного місяця. Такі покази вважатимуться даними 
                      на перше число календарного місяця.</p>
                    <p>Покази передані в будь-який інший день календарного місяця є вихідними даними для визначення даних комерційного обліку на перше число розрахункового періоду.</p>
                    <p>Якщо у вказаний період покази не будуть передані (за умови, що лічильник не оснащений засобами дистанційної передачі даних), фактичний обсяг 
                      розподілу та споживання електричної енергії визначається Оператором системи розподілу (ОСР) розрахунковим шляхом за значенням середньодобового обсягу 
                      споживання.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={8}>
                <Accordion.Header><span>08</span>Чому побутовий споживач повинен подавати покази?</Accordion.Header>
                  <Accordion.Body>
                    <p>п. 3.3 Договору про надання послуг з  розподілу (передачі) електричної енергії «Споживач, що є побутовим, зобов’язаний щомісяця знімати 
                      фактичні показання лічильника та передавати їх Оператору системи розподілу.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={9}>
                <Accordion.Header><span>09</span>Як передати покази Оператору системи розподілу?</Accordion.Header>
                  <Accordion.Body>
                    <p>Перелік ОСР, які діють на території Львівської області та контактна інформація куди подавати покази електролічильника:</p>
                    <h4>ПрАТ «ЛЬВІВОБЛЕНЕРГО»:</h4>
                    <ul>
                      <li><Link to={'email:pokaz@loe.lviv.ua'}>електронною поштою;</Link></li>
                      <li>в онлайн-сервісі <Link to={'https://info.loe.lviv.ua/'} target='_blank'>«Персональний кабінет»;</Link></li>
                      <li>SMS-повідомленням на короткий номер 5068;</li>
                      <li>за номером 295-85-13 без з'єднання з оператором;</li>
                      <li>за номером 5068 (дзвінок платний);</li>
                      <li>через cервіси подачі показників у <Link to={'https://chats.viber.com/loebot'} target='_blank'>Viber</Link> та <Link to={'https://t.me/loeserviceBot'} target='_blank'>Telegram</Link> </li>
                    </ul>
                    <h4>ПАТ «УКРЗАЛІЗНИЦЯ»:</h4>
                    <Table striped bordered hover className='info-table'> 
                    <tbody>
                      <tr>
                        <td rowSpan={2}>УЗ Cтрий</td>
                        <td rowSpan={2}><Link to={'tel:0324599875'}>(03245) 9-98-75</Link> </td>
                        <td><Link to={'tel:380997762186'}>+380997762186</Link> </td>
                      </tr>
                      <tr>
                        <td><Link to={'tel:380683512560'}>+380683512560</Link> </td>
                      </tr>
                      <tr>
                        <td>УЗ Самбір</td>
                        <td><Link to={'tel:0323672310'}>(03236) 7-23-10</Link> </td>
                        <td><Link to={'tel:380956095900'}>+380956095900</Link> </td>
                      </tr>
                      <tr>
                        <td>УЗ Тернопіль</td>
                        <td><Link to={'tel:0352472277'}>(0352) 47-22-77</Link> </td>
                        <td><Link to={'tel:0352472900'}>(0352) 47-29-00</Link> </td>
                      </tr>
                      <tr>
                        <td>УЗ Рівне</td>
                        <td><Link to={'tel:0362423484'}>(0362) 42-34-84</Link> </td>
                        <td><Link to={'tel:380956514286'}>+380956514286</Link> </td>
                      </tr>
                      <tr>
                        <td>УЗ Львів</td>
                        <td colSpan={2}><Link to={'tel:0322268069'}>032 226 80 69</Link> </td>
                      </tr>
                    </tbody>
                    </Table>
                    <h4>ТОВ «НАФТОГАЗ ТЕПЛО»:</h4>
                    <Table striped bordered hover className='info-table'>
                    <tbody>
                      <tr>
                        <td>м. Новояворівськ</td>
                        <td><Link to={'tel:380678214497'}>+380 67 821 44 97</Link> </td>
                      </tr>
                      <tr>
                        <td>м. Новий Розділ</td>
                        <td><Link to={'tel:380678214487'}>+380 67 821 44 87</Link> </td>
                      </tr>
                    </tbody>
                    </Table>
                    <h4>ТОВ «НАФТОГАЗ ТЕПЛО»:</h4>
                    <ul>
                      <li>за номером телефону <Link to={'tel:0800504020'}>0800-50-40-20</Link></li>
                    </ul>
                    <h4>ВАТ «ТЕРНОПІЛЬОБЛЕНЕРГО»:</h4>
                    <ul>
                      <li>за номером телефону <Link to={'tel:0800509040'}>0800-50-90-40</Link></li>
                    </ul>
                    <h4>ДП «РЕГІОНАЛЬНІ ЕЛЕКТРИЧНІ МЕРЕЖІ»:</h4>
                    <ul>
                      <li>за номерами телефонів: <Link to={'tel:0324943414'}>03249 434 14</Link>, <Link to={'tel:0324943413'}>03249 434 13</Link></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={10}>
                <Accordion.Header><span>10</span>Як подати показ електроенергії у ТОВ «Львівенергозбут»?</Accordion.Header>
                  <Accordion.Body>
                    <p>У  ТОВ «Львівенергозбут» Ви можете подати показ:</p>
                    <ul>
                      <li>у Мобільному застосунку</li>
                      <Link to={"https://play.google.com/store/apps/details?id=com.utilsuite.lez.prod&amp;utm_source=site&amp;utm_medium=botton"} target='_blank'>
                        <img height="68" width="210" alt="and" src="https://lez.com.ua/uploads/images/pidpys_do_lysta_(6).png"/>
                        </Link>
                      <Link to={'https://apps.apple.com/ua/app/%D0%BB%D0%B5%D0%B7/id1628053911?l=uk&utm_content=footer'} target='_blank'>
                       <img height="68" width="210" alt="app" src="https://lez.com.ua/uploads/images/pidpys_do_lysta_(5).png"/>
                      </Link>          
                      <li>в <Link to={'/page/personalnyi_kabinet'}> Персональному кабінеті</Link> ТОВ «Львівенергозбут»</li>
                      <li>у чатах Viber та Telegram;</li>
                      <li>через Голосове меню без з’єднання з оператором за телефонами Кол-центру: <Link to={'tel:0676370568'}>067-637-05-68;</Link> <Link to={'tel:0506370568'}>050-637-05-68;</Link> <Link to={'tel:0322420568'}>032-242-05-68;</Link> <Link to={'tel:0736370568'}>073-637-05-68;</Link></li>
                    </ul>
                    <p>ТОВ «Львівенергозбут» не здійснює визначення обсягів спожитої електричної енергії. Подані споживачами покази будуть передані відповідному 
                      Операторам системи розподілу до мереж якого споживач технологічно приєднаний.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={11}>
                <Accordion.Header><span>11</span>Який термін оплати за електроенергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Рахунки за спожиту електроенергію оплачуються побутовими споживачами протягом <b>10 робочих днів від дня отримання рахунку</b> або в інший термін, 
                      передбачений договором, але <b>не пізніше 20 календарного дня після закінчення розрахункового періоду.</b></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={12}>
                <Accordion.Header><span>12</span>Які найзручніші способи оплати за спожиту електроенергію?</Accordion.Header>
                  <Accordion.Body>
                    <p>Побутовим споживачам доступні такі способи оплати за спожиту електроенергію:</p>
                    <ul>
                    <li>на сторінці оплати на сайті;</li>
                    <li>у <Link to={'https://my.lez.com.ua/'}>Персональному кабінеті;</Link></li>
                    <li>в <Link to={'/page/app'}>Мобільному застосунку;</Link></li>
                    <li>у чат-ботах Viber чи Telegram;</li>
                    <li>оплата за електроенергію також доступна в платіжних терміналах у таких банках та установах: АТКБ «Приватбанк», ПАТ «Ощадбанк», 
                      ПАТ «Банк Фамільний», «Easypay», «iPay» та «City 24»;</li>
                    <li>оплата за електроенергію також доступна у пунктах прийому платежів у таких банках та установах: АТКБ «Приватбанк», 
                      ПАТ «Ощадбанк», ПАТ «Банк Фамільний», АТ АКБ Банк «Львів», АТ АБ «Рада Банк», «Укрпошта».</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={13}>
                <Accordion.Header><span>13</span>Коли буде зараховано оплату за особовим рахунком?</Accordion.Header>
                  <Accordion.Body>
                    <p>Згідно п.4.4 Правил роздрібного ринку електричної енергії затверджених постановою НКРЕКП (Національна Комісія, що здійснює державне регулювання 
                      у сферах енергетики та комунальних послуг) № 312 від 14 березня 2018 року  із змінами і доповненнями (далі - ПРРЕЕ) датою здійснення 
                      оплати за виставленим платіжним документом є дата, на яку оплачена сума коштів зараховується на поточний рахунок із спеціальним режимом використання 
                      електропостачальника.</p>
                  </Accordion.Body>
                </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
  )
}

export default AccordionInfoCli