import { useTheme } from '../hooks/useTheme';
import TitleBlock from "../components/TitleBlock";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import { debounce } from '../utils/debounce';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useEffect, useRef, useState } from 'react';
import { getSearchPage } from '../Axios/getPage';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';

const NoPage = ({error}) => {

  const {theme, SetTheme} = useTheme();
  const location = useLocation();

  const [searchList, SetSearchList] = useState([]);
  const searchInput = useRef(null);

  useEffect(()=>{
    axios.get(`https://api.lez.com.ua/site-monitor/${location.state?.id}`).catch((err)=>{})
  }, [location.state?.id])
 
  function handleSearch(e) {
    if (e.target.value !== '') { 
      getSearchPage(e.target.value).then((response)=>{
        const data = response['hydra:member'];
        SetSearchList(data);
      })
    }
    else { SetSearchList(''); }
  }

    return (
        <section className='no-page'>
          <Header/>
          <div className="no-page__inner">
          <TitleBlock title={error} type={'Сторінка відсутня'} addClass={'error-title'}/>
          {error === '404'  &&
          <div className="search-page">
          <TitleBlock subtitle={'Спробуйте знайти інформацію в інших розділах, або скористайтесь пошуком:'} addClass={'error-title-sub'}/>    
          <Container>
                <Row>
                    <Col md={12} className="search-page__input">
                    <input type="text" placeholder='Введіть слово для пошуку' onChange={debounce(handleSearch, 200)} ref={searchInput}/>
                    {searchList?.length > 0 &&
                        <div className="search-result">
                            {searchList.map((search, index)=>{
                            return <NavLink to={'/page/'+ search.synonym} className="search-result__item" key={index}>
                                  <p>{search.name}</p>
                                </NavLink>
                            })}
                    </div>
                    }
                    </Col>
                </Row>
          </Container>
          </div> }
          </div>
          <Footer setTheme = {SetTheme} theme = {theme}/>       
        </section>
    );
};

export default NoPage;