import TitleBlock from "../components/TitleBlock";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fragment, useEffect, useState } from "react";
import NewsItem from "../components/News/NewsItem";
import { getNewsDate } from "../Axios/getNews";
import * as dayjs from 'dayjs';
import Loader from "../components/Loader";
import PaginationLez from "../components/PaginationLez";
import { useSearchParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { getLastImg, monthList, setDeafaultImg, yearList } from "../utils/news";

const News = () => {

  const [newsList, SetNewsList] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [totalPage, SetTotalPage] = useState(0);
  const [month, SetMonth] = useState('');
  const [year, SetYear] = useState('');

  let [searchParams, setSearchParams] = useSearchParams({page: '1', from: 0, to: 0});

  function initTotalPages(page) {
    const url = new URL("https://"+ page)
    const params = new URLSearchParams(url.search)
    const total = parseInt(params.get('page'));
    SetTotalPage(total);
  }  

  const handleChangePage = (currentPage)=> {
    setSearchParams({page : currentPage, from: searchParams.get('from'), to: searchParams.get('to') });
  }

  const handleDate = (year, month) => {
    let currentDateAfter = 0
    let currentDateBefore = 0
    // пошук за вибраним роком
    if (year.length > 0 && month.length === 0){
      currentDateAfter = dayjs(`${year}-01-01`).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      currentDateBefore = dayjs(`${year}-01-01`).add(1,'y').format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      return [currentDateAfter, currentDateBefore]
    }
    // пошук місяць - рік
    if (year.length && month.length){
      currentDateAfter = dayjs(`${year}-${month}-01`).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      currentDateBefore = dayjs(`${year}-${month}-01`).add(1,'M').format('YYYY-MM-DDTHH:mm:ssZ[Z]');
      return [currentDateAfter, currentDateBefore]
    }
    return [currentDateAfter, currentDateBefore];
  }

  const monthHandle = (event) => {
    SetMonth(event.target.value); 
    const [currentDateAfter, currentDateBefore] = handleDate(year, event.target.value);
    if (event.target.value === '') {
      setSearchParams({page : 1});
      searchParams.delete('from');
      searchParams.delete('to');
    }
    else {
      setSearchParams({page : 1, from: currentDateAfter, to: currentDateBefore});
    }
  }

  const yearHandle = (event) => {
    SetYear(event.target.value); 
    const [currentDateAfter, currentDateBefore] = handleDate(event.target.value, month);
    if (event.target.value === '') {
      setSearchParams({page : 1});
      searchParams.delete('from');
      searchParams.delete('to');
    }
    else {
      setSearchParams({page : 1, from: currentDateAfter, to: currentDateBefore});
    }
  }
    
    useEffect(()=>{
        window.scrollTo(0,0);
        let active = false;
        const pagecurrent = parseInt(searchParams.get('page'));
        const currentAfter = searchParams.get('from') || 0;
        const currentBefore = searchParams.get('to') || 0;
                  
        getNewsDate(SetLoading, currentAfter, currentBefore, pagecurrent).then((response)=>{
          const data = response['hydra:member'];
          const view = response['hydra:view'];
          initTotalPages(view['hydra:last']);

          const newData = data.map((item)=>{
              return { 
                id : item.id,
                idImg: item.id.toString().slice(-1),
                title: item.name,  
                time: dayjs(item.date).format('DD.MM.YYYY'), 
                imglink: item.imgLink ? process.env.REACT_APP_SERVER_URL + item.imgLink : getLastImg(item.newsFull),
                active: item.active, 
              };
          })
          if(!active){
            SetNewsList(newData);
          }
        });

        return () => {
          active = true;
        };

    },[searchParams])

  return (
    <>   
        <Container className="news">
        <TitleBlock title={'Наші новини'} />
        <Row>
          <Col md={12} className='news-date'>
          <Form.Select  size="sm" 
            onChange={monthHandle} 
            value={month.length <= 0 ? '' : searchParams.get('from')?.split('-')[1]}
          >
              <option selected='selected' value={''}>Місяць</option>
              {monthList.map(month=>{
                return <option key={month.id} value={month.id}>{month.name}</option>
              })}
            </Form.Select>
            <Form.Select className="news-date__year" size="sm" 
              onChange={yearHandle} 
              value={parseInt(searchParams.get('from'))}
            >
              <option selected='selected' value={''}>Рік</option>
              {yearList.map((year,index)=>{
                return <option key={index} value={year}>{year}</option>
              })}
            </Form.Select>
          </Col>
        </Row>
        {
          loading ? <Loader/> 
          : <Row>
           {newsList.length ? newsList.map((news)=>{
                if(news.active === 'on') {
                  return <Col md={4} key={news.id} className="news__inner">
                          <NewsItem
                          id={news.id} 
                          path={news?.imglink || setDeafaultImg(news.idImg).path}
                          time={news.time} 
                          title = {news.title}/>
                        </Col>
                }
                return <Fragment key={news.id}></Fragment> })
              : <Col md={12} className="news__inner">
                  <h2 class="news-title">Новин не знайдено.</h2>
                </Col>
            }
          </Row>}
           <Row>
                {totalPage>1 && 
                <PaginationLez
                  changePage={handleChangePage}
                  currentPage={parseInt(searchParams.get('page'))}
                  total={totalPage}
                />}
            </Row>
        </Container>
    </>
  )
}

export default News