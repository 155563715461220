import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import sample from '../../img/1.png';
import { setDeafaultImg } from '../../utils/news';

const NewsImage = ({path,height,styl}) => {

  return (
    <LazyLoadImage
       className={"img-fluid img-news-item " + styl}
       alt={'news'}
       placeholderSrc = {sample}
       effect="blur"
       height={height}
       width={'100%'}
       src={path}
       onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = setDeafaultImg('1').path;
      }}
      >
    </LazyLoadImage>
  )
}

export default NewsImage