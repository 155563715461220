import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import TitleBlock from '../TitleBlock'

const AboutSlide = () => {
  return (
    <>
    <Row>
      <Col>
        <TitleBlock subtitle={`Про нас`} type ={'Енергопостачальна компанія ТОВ «Львівенергозбут» — компанія, котра постачає електричну енергію та природний газ.'}/>
      </Col>
    </Row>
    <Row>
        <Col md={4} className='about'>
            <div className="about__inner">
                <img className='homeslide-rectangle' src="/img/rectangle-about.svg" alt="rec"/>
                <p>
                    Наша енергопостачальна компанія створена у 2018 році на виконання вимог Закону України «Про ринок електричної енергії».
                </p>
            </div>
        </Col>
        <Col md={4} className='about'>
            <div className="about__inner">
                <img className='homeslide-rectangle' src="/img/rectangle-about.svg" alt="rec"/>
                <p>
                    Ми — постачальник універсальних послуг для побутових та малих непобутових споживачівна території Львівської області, 
                    постачальник електричної електроенергії для споживачів за вільною ціною на території України та постачальник природного газу для усіх категорій споживачів.
                </p>
            </div>
        </Col>
        <Col md={4} className='about'>
            <div className="about__inner">
                <img className='homeslide-rectangle' src="/img/rectangle-about.svg" alt="rec"/>
                <p>
                    Якість обслуговування наших клієнтів є одним із найголовніших принципів для компанії. 
                    Ми планомірно впроваджуємо заходи, аби зробити наші послуги максимально комфортними та зручними.
                </p>
            </div>
        </Col>
    </Row>
    </>
  )
}

export default AboutSlide