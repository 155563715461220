import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import SendPokaz from '../../components/SendPokaz/SendPokaz'
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import OtherServices from "../../components/SendPokaz/OtherServices";

const SendPokazCli = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, [])

  const [,,theme] = useOutletContext();
  
  return (
    <>
      {theme === 'light' && <div className="mont-block"></div> }
      <TitleBlock title={'Передати показники лічильника'} addClass={'pokaz-title'} type={'Увага! Відповідно до п.8.6.3 Кодексу комерційного обліку електричної енергії, показники спожитої електроенергії побутовим клієнтам слід повідомляти щомісяця у період, що починається за два календарні дні до кінця розрахункового місяця та закінчується на третій календарний день наступного місяця.'}/>
      <SendPokaz/>
      <OtherServices/>
      <SliderNews/>
   </>
  )
}

export default SendPokazCli