import React from 'react'
import Col from 'react-bootstrap/esm/Col'

const ComTarifPlansItem = ({nameTariff, isOsr, payment, nameCom, url, cols}) => {
  return (
    <Col md={cols} className={cols===3 ? 'tarif-plans short' : 'tarif-plans long'}>
        <a className="tarif-plans__inner" href={url}>
          <p className="tarif-plans__offer-type">{nameCom}</p>
          {cols === 3 &&
          <>
          {isOsr 
            ? <p className="tarif-plans__osr active">З тарифом ОСР</p>
            : <p className="tarif-plans__osr">Без тарифу ОСР</p>}
          </>
          }
            <div className="tarif-plans__price">
                { payment === "Оплата по факту" && <><p>{payment}</p> <img src="/img/tarif-comer1.svg" alt=""/> </> }
                { payment === "Оплата плановими платежами" && <><p>{payment}</p> <img src="/img/tarif-comer2.svg" alt=""/> </>}
                { payment === "Попередня оплата" && <><p>{payment}</p> <img src="/img/tarif-comer3.svg" alt=""/> </>}
            </div>
            {cols === 3 && <>
              <p className="tarif-plans__define">Тарифний план</p>
              <p className="tarif-plans__name">{nameTariff}</p>
            </>
            }
            <img src="/img/tarif-comer-download.svg" className="download" alt="download"/>
        </a>      
    </Col>
  )
}

export default ComTarifPlansItem