import axios from 'axios';
import React, { useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import ReactInputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import { getAbonPhoneName } from '../../Axios/forms';

const Eaccount = () => {
    
  const [values, setValues] = useState({kode: '', email: '', phone: ''});
  const [isNew, setIsNew] = useState(true);

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value
      });
  };

  function sendForm (event) {
    event.preventDefault();

    if (values.kode.length !== 10){
        toast.error("Ви ввели невірний особовий рахунок. Спробуйте ще раз.");
        setValues({kode: '', email: '', phone: ''});
        return false;
    }
    if (parseInt(values.kode) < 100000000 || parseInt(values.kode) > 4100000000){
        toast.error("Ви ввели невірний особовий рахунок. Спробуйте ще раз.");
        setValues({kode: '', email: '', phone: ''});
        return false;
    }
    if (parseInt(values.phone).toString().length !== 12) {
        toast.error("Ви ввели невірний номер телефону. Спробуйте ще раз.");
        setValues({kode: '', email: '', phone: ''});
        return false;
    }

    getAbonPhoneName(values.kode).then(response =>{
        const [data]  = response['hydra:member'];

        const article = {
            persuid: values.kode,
            phone: values.phone,
            mail: values.email,
            active: 1
        }

        // // якщо заповнене поле пошта додаємо до оновлення
        // if (values.email?.length > 0) {
        //     article.mail = values.email;
        // }

        const url = process.env.REACT_APP_SERVER_URL + `/api/lep_abon_phone_mails/${data?.id}`;
        const config = { headers: { 'content-type': 'application/json', } }

        // шлях для кнопки "Надіслати" (новий споживач)
        if (isNew) {
            if(data !== undefined && data?.active === 1) {
                axios.put(url, article, config).then(()=>{
                    toast.success("Ваші дані оновлено.");
                }).catch(()=>{
                    toast.error("Помилки при обробці даних. Повторіть пізніше.");
                }).finally(()=>{
                    setValues({kode: '', email: '', phone: ''});
                })
            }
    
            // якщо особовий додано але він відписаний оновлюємо active + відправлені дані
            if(data?.active === 0) {
                axios.put(url, article, config).then(()=>{
                    toast.success("Особовий рахунок додано до розсилки.");
                }).catch(()=>{
                    toast.error("Помилки при обробці даних. Повторіть пізніше.");
                }).finally(()=>{
                    setValues({kode: '', email: '', phone: ''});
                })
            }
    
            // якщо особовий новий добавляємо новий запис
            if (data === undefined) {
                axios.post(process.env.REACT_APP_SERVER_URL + "/api/lep_abon_phone_mails", article, config).then(()=>{
                    toast.success("Особовий рахунок додано до розсилки.");
                }).catch(()=>{
                    toast.error("Помилки при обробці даних. Повторіть пізніше.");
                }).finally(()=>{
                    setValues({kode: '', email: '', phone: ''});
                })
            }
        } // шлях для кнопки "Відписатись" (існуючий споживач)
        else {
            if(data !== undefined && data?.active === 1) {
                axios.put(url, {...article, active: 0 }, config).then(()=>{
                    toast.success("Особовий рахунок видалено з розсилки.");
                }).catch(()=>{
                    toast.error("Помилки при обробці даних. Повторіть пізніше.");
                }).finally(()=>{
                    setValues({kode: '', email: '', phone: ''});
                })
            }
            // якщо знайшло відписаний особовий 
            if(data?.active === 0) { 
                toast.warn("Ваш особовий рахунок вже видалений з розсилки.");
                setValues({kode: '', email: '', phone: ''});
                return false;
            }

            // якщо особовий не знайшло
            if (data === undefined) {
                toast.warn("Ваш особовий рахунок вже видалений з розсилки.");
                setValues({kode: '', email: '', phone: ''});
                return false;
            }
        }
    })
  }

  return (
    <section className='green application-form'>
    <Container>
    <Row>
    <div className="col-md-12">
        <div className="row">
            <div className="h2-green col-md-6">
                <h3>Рахунок за електроенергію</h3><p>Як скористатись новою послугою? Для цього заповніть, будь ласка, форму:</p>
            </div>
        </div>
    </div>
    <div className="application-form__inner">
        <div className="application-form__inner-gray">
            <form type="submit" onSubmit={sendForm}>
                <div className="green-inner">
                    <div className="header-green col-md-6">
                        <div className="form-left d-flex align-items-center height0">
                            <span className="number-form">01</span><span className="number-text"> Десятизначний код клієнта</span>
                        </div>
                        </div>
                        <div className="header-green col-md-6">
                            <input onChange={handleInputChange} value={values['kode']} name="kode" type="number" placeholder="десятизначний код" className="form-control" required/>
                        </div>
                </div>
                <div className="green-inner">
                    <div className="header-green col-md-6">
                        <div className="form-left d-flex align-items-center height0">
                            <span className="number-form">02</span><span className="number-text">Електронна пошта</span>
                        </div>
                    </div>
                    <div className="header-green col-md-6">
                        <input onChange={handleInputChange} value={values['email']} name="email" type="email" placeholder="email" className="form-control" />
                    </div>
                </div>
                <div className="green-inner">
                    <div className="header-green col-md-6">
                        <div className="form-left d-flex align-items-center height0">
                            <span className="number-form">03</span><span className="number-text">Номер мобільного телефону</span>
                        </div>
                    </div>
                    <div className="header-green col-md-6">
                    <ReactInputMask 
                        mask={'380999999999'} 
                        onChange={handleInputChange} 
                        value= {values['phone']}
                        name="phone"
                        type="tel"
                        placeholder="телефон" 
                        className='form-control'
                        required
                    />
                    </div>
                </div>
                <div className="green-inner">
                    <div className="header-green col-md-6">
                        <div className="form-left d-flex align-items-center height0">
                            <span className="number-form">04</span><span className="number-text">Надати згоду</span>
                        </div>
                    </div>
                    <div className="header-green col-md-6">
                        <div className="form-check">
                            <input required type="checkbox" id="default-checkbox4" className="form-check-input"/>
                            <label title="" for="default-checkbox4" className="form-check-label">Надаю згоду ТОВ «Львівенергозбут» на автоматизовану обробку моїх персональних даних, згідно із чинним законодавством, та можливу їх передачу третім особам, які мають право на отримання цих даних.
                            </label>
                        </div>
                    </div>
                </div>
                <div className="green-inner">
                    <div className="header-green col-md-6"><p className="text-left fs-12">Поле помічене зірочкою «*»<br/> обов’язкове для заповнення</p></div>
                    <div className="header-green col-md-6">
                        <button onClick={()=>{setIsNew(true)}} type="submit" className="btn-submit">Надіслати</button>
                        <button onClick={()=>{setIsNew(false)}} type="submit" className="btn-submit mt-3">Відмовитись</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </Row>
    </Container>
    <ToastContainer /> 
    </section>
  )
}

export default Eaccount