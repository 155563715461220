import React from 'react'

const ComDowload = ({url,content, target}) => {

  return (
    <a href={url} target={target ? target : "_self"}>
        <p>{content}</p>
        <img src="/img/tarif-comer-download.svg" className="download-block__img" alt="download"/>
    </a>
  )
}

export default ComDowload