import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleBlock from "../components/TitleBlock";
import SliderNews from "../components/News/SliderNews";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

const RatingCons = () => {

    useEffect(() => {
          window.scrollTo(0,0);
    }, []);
  
    const param = useParams();
    var src = 'https://o-containe.lez.com.ua/rating_cons/'+param.kons_id;
    return (
      <>
        <TitleBlock title={'Оцінювання консультанта'} type={''}/>
        {
          <>
          <Container>
                    <Row>
                        <Col>
                            <TitleBlock subtitle={``}/>
                        </Col>
                    </Row>
                </Container>
            
            <section className="green application-form">
            <Container>
            <Row>
                                
                <div className="application-form__inner" id = "Service-Form">
                <div className="application-form__inner-gray">
                <iframe className='service-iframe' title='Оцінювання консультанта' src ={src}></iframe>
                </div>
                </div>
                </Row>
            </Container>
            
            </section>
           </>
        }
        <SliderNews/>
      </>
    )
  }
  
  export default RatingCons
