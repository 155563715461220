import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import { useEffect } from "react";
// import SearchVacancies from "../../components/Vacancies/SearchVacancies";
import { useOutletContext } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


const Vacancies = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, [])

  const [,,theme] = useOutletContext();

  return (
    <>
      {theme === 'light' && <div className="mont-block"></div>}
      <TitleBlock title={'Вакансії'} type={'Якщо ви хочете працювати у сучасній та динамічній компанії — цей розділ нашого сайту саме для Вас.'}/>
      {
          <>
                     
            <section className="green application-form">
            <Container>
            <Row>
                                
                <div className="application-form__inner" id = "Service-Form">
                <div className="application-form__inner-gray">
                <iframe className='vakancies-iframe' title='Вакансії' src ={'https://o-containe.lez.com.ua/vakansiji'}></iframe>
                </div>
                </div>
                </Row>
            </Container>
            
            </section>
           </>
        }
      <SliderNews/>
   </>
  )
}

export default Vacancies
