import React, { forwardRef, useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import NavigationElementTab from './NavigationElementTab';
import { getMenuList } from '../../Axios/getPage';
import { orderServices } from '../../utils/news';

const NavigationElement = forwardRef((props, ref) => {

const [menuElectro, setMenuElectro] = useState([]);
const [menuGaz, setMenuGaz] = useState([]);


useEffect (()=>{
  let active = false;
  getMenuList(`top-menu-${props.type}`).then((response)=>{
    const [data] = response['hydra:member'];
    const [electro, gaz] = data.menuItems;

    if(!active) {
      setMenuElectro(orderServices(electro.children));
      setMenuGaz(orderServices(gaz.children));
    }
  });
  return () => {
    active = true;
  };
  
},[props.type])

return (
  <div className={`top-navigation ${props.type}`} ref={ref}>
      <div className="top-navigation__close" onClick={()=>props.handleClass(ref, props.type)}>
          <img src='/img/close-menu.svg' alt="" />
      </div>
      <Tab.Container className="tabs" defaultActiveKey="first">
      <Nav className="tabs__title">
          <Nav.Item>
            <Nav.Link eventKey="first" className='tabs__title-electro'>Електроенергія</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second" className='tabs__title-gaz'>Природний Газ</Nav.Link>
          </Nav.Item>
      </Nav>
      <Tab.Content>
      <Tab.Pane eventKey="first" className="tabs__electro">
       <NavigationElementTab ref={ref} handleClass = {props.handleClass} typeClass={props.type} links = {menuElectro}/>
      </Tab.Pane>
      <Tab.Pane eventKey="second" className="tabs__gaz">
        <NavigationElementTab ref={ref} handleClass = {props.handleClass} typeClass={props.type} links = {menuGaz}/>
      </Tab.Pane>
      </Tab.Content>
      </Tab.Container>
  </div>
)
});

export default NavigationElement