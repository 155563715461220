import React, { useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react';

function MainSlide({handleActive}) {
   
 const swiperSlide = useSwiperSlide();

 useEffect(()=>{
  handleActive(swiperSlide.isActive)
 },[swiperSlide.isActive,handleActive])

  return (
    <>
        <h1>Постачаємо <br/>
        <span className="main-slide__electro"> електроенергію</span> <br/>
        <span className="main-slide__gaz">і природний газ</span><br/>
        <span className="main-slide__territory"> на території </span>
        <span className="main-slide__ukraine">України</span></h1>
    </>
  )
}

export default MainSlide
