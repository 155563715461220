
import { MapContainer, TileLayer, Popup, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import marker from '../../img/marker-lez.svg'

const icon = new L.Icon({
    iconUrl: marker,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(23, 32),
    className: 'leaflet-div-icon'
});

function SetViewOnClick ({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
  
    return null;
}

const CustomMap = ({mapList}) => {

    const position = [49.84304942196432, 24.017274606306195]
    // console.log(mapList?.[0]?.cordList?.[0]);

    return  ( <MapContainer center={mapList?.[0]?.cordList?.[0] || position} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                {mapList.map((map)=>{
                    return map.cordList.map((pos, index)=>{
                        return <Marker position={pos} icon={icon} key={index}>
                                <Popup>
                                    {map.description}
                                </Popup>
                            </Marker>
                    })
                })}
                <SetViewOnClick coords={mapList?.[0]?.cordList?.[0] || position} />
                </MapContainer> )
    }
  
 export default CustomMap
