import Col from "react-bootstrap/esm/Col"
// import { Link } from "react-router-dom"

const PokazBlock = ({setGreenActive, pokazValues, handleCountChange, setPokaz, initialPokaz, type, index}) => {

    return (             
            <div className="green-inner" onFocus={setGreenActive}>
            <Col md={6} className= "header-green">
                <div className="form-left d-flex align-items-center height0"><span className="number-form">0{index}</span> <span className="number-text">Введіть показник лічильника {type}</span></div>
            </Col>
            <Col md={6} className= "header-green">
                <div className="bg-grey-line">
                    <input type="number" className="input1" name='first' value={pokazValues.first} min={0} max={9} onChange={(e)=> {handleCountChange(e,pokazValues,setPokaz)}} onFocus = {()=>{setPokaz(initialPokaz)}} placeholder="0" required/>
                    <input type="number" className="input1" name='sec' value={pokazValues.sec} min={0} max={9} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}} placeholder="0" required/>

                    <input type="number" className="input1" name='third' value={pokazValues.third} min={0} max={9} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}} placeholder="0" required/>
                    <input type="number" className="input1" name='four' value={pokazValues.four} min={0} max={9} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}} placeholder="0" required/>

                    <input type="number" className="input1" name='five' value={pokazValues.five} min={0} max={9} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}} placeholder="0" required/>
                    <input type="number" className="input1" name='six' value={pokazValues.six} min={0} max={9} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}} placeholder="0" required/>

                </div>
                {/* <span className="kome">,</span>
                <div className="bg-grey-line">
                <input type="number" className="input1" name='seven' min={0} max={9} value={pokazValues.seven} onChange = {(e)=> {handleCountChange(e,pokazValues,setPokaz)}}  placeholder="0" required/>
                </div> */}
            </Col>
            </div>
    )}
  
  export default PokazBlock