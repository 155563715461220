import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { forwardRef, useRef, useState } from 'react';
import CustomMap from './Map/CustomMap';

const PickTsok = forwardRef((props, ref) => {

    const map = useRef(null);
  
    const tsokList = [
        {id: 1, city: 'Львів', description: 'Центральний ЦОК', addr: 'м.Львів вул. Шевченка, 1', cordList: [[49.842746225106886, 24.01580006659527]], 
            tel: props.type === 'Для компаній' ? '(032) 259-51-32' : '(032) 259-51-31',
        },
        {id: 2, city: 'Старий Самбір', description: 'Самбірський ЦОК', addr: 'Львівська обл., м. Старий Самбір, вул. Лева Галицького 96 Б', cordList: [[49.43918532454134, 23.00004846842642]],tel: '(032) 259-59-59', telVN: '19-911'},
        {id: 3, city: 'Самбір', description: 'Самбірський ЦОК', addr: 'Львівська обл., м. Самбір, вул. Мазепи, 15',cordList: [[49.51810350690887, 23.201493597266083]], tel: '(032) 259-59-59', telVN: '19-910'},
        {id: 4, city: 'Турка', description: 'Самбірський ЦОК', addr: 'Львівська обл., м. Турка вул. Шептицького, 3 д', cordList: [[49.15192028201282, 23.037293968412598]], tel: '(032) 259-59-59', telVN: '19-912'},
        {id: 5, city: 'Дрогобич', description: 'Дрогобицький ЦОК', addr: 'Львівська обл., м. Дрогобич, вул. Бориславська, 2', cordList: [[49.34923547820936, 23.50382835307877]], tel: '(032) 259-59-59', telVN: '12-910'},
        {id: 6, city: 'Борислав', description: 'Дрогобицький ЦОК', addr: 'Львівська обл., м. Борислав, вул. Шевченка, 179б, каб.101', cordList: [[49.277079763563435, 23.40870271074698]], tel: '(032) 259-59-59', telVN: '12-911' },
        {id: 7, city: 'Стрий', description: 'Стрийський ЦОК', addr: 'Львівська обл., м. Стрий, вул. Успенська, 3', cordList: [[49.2534727286631, 23.851327026089102]], tel: '(032) 259-59-59', telVN: '13-910'},
        {id: 8, city: 'Сколе', description: 'Стрийський ЦОК', addr: 'Львівська обл., м. Сколе, вул. Чайківського, 11-в', cordList: [[49.03924133515764, 23.510509181899714]], tel: '(032) 259-59-59', telVN: '13-914'},
        {id: 9, city: 'Миколаїв', description: 'Стрийський ЦОК', addr: "Львівська обл., м. Миколаїв,вул. Возз'єднання, 5-Б", cordList: [[49.52820041352611, 23.97160706441792]], tel: '(032) 259-59-59', telVN: '13-911'},
        {id: 10, city: 'Жидачів', description: 'Стрийський ЦОК', addr: 'Львівська обл., м. Жидачів, вул. Енергетична, 5/1', cordList: [[49.367499838334794, 24.138637181915577]], tel: '(032) 259-59-59', telVN: '13-913'},
        {id: 11, city: 'Яворів', description: 'Яворівський ЦОК', addr: 'Львівська обл., м.Яворів,площа Ринок, 8', cordList: [[49.9451994475129, 23.388804441465993]], tel: '(032) 259-59-59', telVN: '14-910'},
        {id: 12, city: 'Мостиська', description: 'Яворівський ЦОК', addr: 'Львівська обл., м. Мостиська, вул. Шевченка, 144-а', cordList: [[49.78326916475689, 23.148223210771473]], tel: '(032) 259-59-59', telVN: '14-911'},
        {id: 13, city: 'Червоноград', description: 'Червоноградський ЦОК', addr: 'Львівська обл., м. Червоноград, просп. Шевченка, 3', cordList: [[50.39606971697656, 24.23336029730893]], tel: '(032) 259-59-59', telVN: '15-910'},
        {id: 14, city: 'Сокаль', description: 'Червоноградський ЦОК', addr: 'Львівська обл. м. Сокаль, вул. Шашкевича, 84, приміщення 1',cordList: [[50.47111144274212, 24.280996339763053]], tel: '(032) 259-59-59', telVN: '15-911'},
        {id: 15, city: 'Радехів', description: 'Червоноградський ЦОК', addr: 'Львівська обл., м. Радехів,вул. Мишуги, 16-а', cordList: [[50.286307631022666, 24.645062453124236]], tel: '(032) 259-59-59', telVN: '15-912'},
        {id: 16, city: 'Золочів', description: 'Золочівський ЦОК', addr: 'Львівська обл., м. Золочів, вул. Шашкевича М., 23а', cordList: [[49.80323396423975, 24.90219872611577]], tel: '(032) 259-59-59', telVN: '16-910'},
        {id: 17, city: 'Буськ', description: 'Золочівський ЦОК', addr: 'Львівська обл., м. Буськ, вул. Шкільна, 2', cordList: [[49.96841858760295, 24.61874329543716]], tel: '(032) 259-59-59', telVN: '16-911'},
        {id: 18, city: 'Броди', description: 'Золочівський ЦОК', addr: 'Львівська обл., м. Броди, вул. Січових Стрільців, 14', cordList: [[50.08347609684386, 25.149497410786044]], tel: '(032) 259-59-59', telVN: '16-912'},
        {id: 19, city: 'Пустомити', description: 'Львівський ЦОК', addr: 'Львівська обл., м. Пустомити, вул. Глинська, 2В', cordList: [[49.7252040141305, 23.90020588378359]], tel: '(032) 259-59-59', telVN: '11-910'},
        {id: 20, city: 'Жовква', description: 'Львівський ЦОК', addr: 'Львівська обл., м. Жовква, вул. Воїнів УПА, буд. 8Г', cordList: [[50.05385231026788, 23.97229801078463]], tel: '(032) 259-59-59', telVN: '11-912'},
        {id: 21, city: 'Городок', description: 'Львівський ЦОК', addr: 'Львівська обл., м. Городок, майдан Гайдамаків, 12А', cordList: [[49.78559835876224, 23.646371953099866]], tel: '(032) 259-59-59', telVN: '11-911'},
        {id: 22, city: "Кам'янка-Бузька", description: 'Львівський ЦОК', addr: 'Львівська обл., м. Кам’янка-Бузька, вул. Ю.Струмила, 2-б', cordList: [[50.10655962534762, 24.342200902847136]], tel: '(032) 259-59-59', telVN: '11-913'},
        {id: 23, city: 'Перемишляни', description: 'Львівський ЦОК', addr: 'Львівська обл., м. Перемишляни, вул. Івасюка, 3 ', cordList: [[49.66158226404648, 24.563973689332915]], tel: '(032) 259-59-59', telVN: '11-914'},
      ]
    
  const[city,setCity] = useState(1);

  function formatNum(str) {
    const regex = /[- ()]/g;
    return str.replace(regex, "");
 }
  
 function cityChange(e) {
    setCity(e.target.value);
    map.current.scrollIntoView({ block: "center", behavior: "smooth" });
 }
  
  const filtredList = tsokList.filter(list=>{
     return list.id === parseInt(city)
  })

  return (
    <section className="green-tsok" ref={ref}>
    <Container>
        <Row>
            <Col md={12}>
                <div className="tsok-line"></div>
                <h3>Оберіть потрібний <br/> Центр обслуговування <br/> клієнтів</h3>
            </Col>    
        </Row>
        <Row>
        <Col md={7} className='tsok-pick__inner'>
        <Row>
            <Col md={6} className='tsok-select'>
            <p className='tsok-desc'>Місто</p>
                <Form.Select size="lg" onChange={cityChange} value={city}>
                {tsokList.map((tsok)=>{
                    return <option key={tsok.id} value={tsok.id}>{tsok.city}</option>
                })}
                </Form.Select>
            </Col>
            {/* <Col md={6} className= 'tsok-count city'>
               <p> <span>23</span> Відділення</p>
            </Col> */}
            <Col md={6} className='tsok-contacts'>
                <p className='tsok-desc'>Контакти</p>
                <Link to = {`tel:${formatNum(filtredList[0].tel)}`} className='tsok-time'>{filtredList[0].tel}</Link> <br />
                {filtredList[0].telVN !== undefined &&
                    <><Link to = {`tel:${formatNum(filtredList[0].telVN)}`} className='tsok-time'>(вн.{filtredList[0].telVN})</Link> <br /></>
                }
                {/* <Link to = {`tel:${formatNum(filtredList[0].vn)}`} className='tsok-time'>{filtredList[0].vn}</Link> */}
                <Link to={props.type === 'Для компаній' ? '/company/assignment' : '/clients/assignment'} className='btn-yellow-white'>ЗА ПОПЕРЕДНІМ ЗАПИСОМ</Link>
            </Col>
        </Row>
        <Row className='tsok-name__inner'>
            <Col md={6} className='tsok-name'>
                <p className='tsok-desc'>Назва Центру обслуговування клієнтів</p>
                <p className='tsok-name__title'>{filtredList[0].description}</p>
            </Col>
            {/* <Col md={6} className= 'tsok-count inner'>
               <p> <span>8</span> ЦОКів</p>
            </Col> */}
        </Row>
        <Row>
            <Col md={6}>
                <p className='tsok-desc'>Адреса</p>
                <p className='tsok-addr'>{filtredList[0].addr}</p>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <p className='tsok-desc'>Графік роботи</p>
                <p className='tsok-day'>Понеділок-Четвер</p>
                <p className='tsok-time'>08:00 — 17:00</p>
                <p className='tsok-day'>П’ятниця</p>
                <p className='tsok-time'>08:00 — 16:00</p>
                <p className='tsok-day'>За попереднім записом</p>
                <p className='tsok-time'>12:12 — 13:00</p>
                <p className='tsok-day'>Субота, Неділя</p>
                <p className='tsok-time'>Вихідний</p>
            </Col>
            {/* <Col md={6} className='tsok-contacts'>
                <p className='tsok-desc'>Контакти</p>
                <Link to = {`tel:${formatNum(filtredList[0].tel)}`} className='tsok-time'>{filtredList[0].tel}</Link> <br />
                <Link to={props.type === 'Для компаній' ? '/company/assignment' : '/clients/assignment'} className='btn-yellow-white'>ЗА ПОПЕРЕДНІМ ЗАПИСОМ</Link>
            </Col> */}
        </Row>
        </Col>
            <Col ref={map} md={5} className='tsok-map__inner' style={{paddingLeft: 0}}>
                <CustomMap mapList={filtredList}/>
            </Col>
        </Row>
    </Container>
   </section>
  )
})

export default PickTsok